import React from 'react';
import Icon, {
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { Avatar, Dropdown, Layout, Menu, Modal } from 'antd';
import OwlLogo from '../../assets/logo-26.svg';
import { Locale } from '../../context/AppProvider';
import { actions as sessionSliceActions } from '../../data/session/logic';
import { generatePath, RouteDefs } from '../Routing/Routing';
import styles from './MainContainer.module.scss';

export const UserContextMenuComponent: React.FC = () => {
  const dispatch = useDispatch();
  const { messages } = React.useContext(Locale);
  const dropdownItems = React.useMemo(() => {
    return [
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: (
          <Link
            data-testid="logout-btn"
            to="#"
            onClick={() => {
              Modal.confirm({
                content: messages['platform.logout_description'],
                onOk: () => {
                  dispatch(sessionSliceActions.requestLogout());
                },
              });
            }}
          >
            {messages['platform.logout']}
          </Link>
        ),
      },
    ];
  }, [messages]);

  return (
    <Dropdown
      placement="topRight"
      menu={{ selectable: false, items: dropdownItems }}
    >
      <Avatar icon={<UserOutlined />} />
    </Dropdown>
  );
};

const MainContainer = () => {
  const { messages } = React.useContext(Locale);

  return (
    <Layout className={styles.layout}>
      <Layout.Header className={styles.header}>
        <Icon className={styles.owlIcon} component={OwlLogo} />
        <Menu
          className={styles.menu}
          selectedKeys={[location.pathname.split('/')[1]]}
          mode="horizontal"
          items={[
            {
              key: 'claims',
              icon: <HomeOutlined />,
              label: (
                <Link to={generatePath(RouteDefs.Claims)}>
                  {messages['claims.title']}
                </Link>
              ),
            },
            {
              key: 'admin',
              icon: <UserSwitchOutlined />,
              label: (
                <Link to={generatePath(RouteDefs.AdminUsers)}>
                  {messages['admin.title']}
                </Link>
              ),
            },
          ]}
          selectable
        />
        <UserContextMenuComponent />
      </Layout.Header>
      <Layout className={styles.content}>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default MainContainer;
