// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-__content--95HP6 {\n  width: 100%;\n}\n\n.components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-__card--KNQHL.components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-__card--KNQHL > .ant-card-body {\n  padding: 0;\n  overflow: auto;\n}", "",{"version":3,"sources":["webpack://./src/components/ClaimOverviewContainer/ClaimReportContainer/ClaimReportOverviewDataContainer/ClaimReportOverviewDataContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAGE;EACE,UAAA;EACA,cAAA;AAAJ","sourcesContent":[".content {\n  width: 100%;\n}\n\n.card {\n  &#{&} > :global(.ant-card-body) {\n    padding: 0;\n    overflow: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-__content--95HP6",
	"card": "components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-__card--KNQHL"
};
export default ___CSS_LOADER_EXPORT___;
