import { createLogger } from '@owl-lib/logger';
import { deserialize, fetchWrapper } from '../fetch';
import type { User } from '../interface';
const logger = createLogger(__filename);

export const SESSION_TIMEOUT_LIMIT = 30_000; // 30 seconds
const sessionApiClient = {
  current: async (): Promise<User> => {
    const url = new URL('/app/v1/users/current', API_BASE_URL);
    logger.trace('sessionApiClient.session.current()');
    return deserialize(
      await fetchWrapper(
        url.href,
        { method: 'GET', credentials: 'include' },
        SESSION_TIMEOUT_LIMIT
      )
    );
  },
  requestLogin: async (data: {
    tenantId: string;
  }): Promise<{ url: string; message: string }> => {
    const url = new URL('/login', API_BASE_URL);
    url.searchParams.set('tenant', data.tenantId);
    logger.trace('sessionApiClient.session.requestLogin()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
  requestNewToken: async (data: {
    authCode?: string;
    state?: string;
  }): Promise<void> => {
    const url = new URL('/token', API_BASE_URL);
    if (data.authCode) {
      url.searchParams.set('authCode', data.authCode);
    }
    if (data.state) {
      url.searchParams.set('state', data.state);
    }
    logger.trace('sessionApiClient.session.refreshToken()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
  requestLogout: async (): Promise<{ url: string; message: string }> => {
    const url = new URL('/logout', API_BASE_URL);
    logger.trace('sessionApiClient.session.requestLogout()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
};

export default sessionApiClient;
