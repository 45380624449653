import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Locale } from '../../../context/AppProvider';
import styles from './UsersContainer.module.scss';

interface DeleteUsersComponentProps {
  deleteUsers: () => void;
  setSelectedRowKeys(selectedRowKeys: string[]): void;
}

const DeleteUsersComponent: React.FC<DeleteUsersComponentProps> = ({
  deleteUsers,
  setSelectedRowKeys,
}) => {
  const { messages } = React.useContext(Locale);
  return (
    <div className={styles.sharedContainer}>
      <Button
        type="default"
        danger
        onClick={() => {
          setSelectedRowKeys([]);
        }}
      >
        {messages['shared.cancel']}
      </Button>
      <Button icon={<DeleteOutlined />} onClick={deleteUsers} type="primary">
        {messages['users.filters.buttons.delete']}
      </Button>
    </div>
  );
};

export default DeleteUsersComponent;
