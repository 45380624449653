import React from 'react';
import dayjs from 'dayjs';
import { Button, Spin, Table, Tag } from 'antd';
import { PRETTY_DATE_FORMAT } from '@owl-frontend/components';
import { Claim } from '../../../api/claims/interface';
import { Locale } from '../../../context/AppProvider';

export const ClaimStatusComponent: React.FC<{
  claimStatus: 'OPEN' | 'CLSD' | string;
}> = ({ claimStatus }) => {
  return (
    <Tag color={claimStatus === 'OPEN' ? 'green' : 'red'}>{claimStatus}</Tag>
  );
};

interface Props {
  data: Claim[];
  loading: boolean;
  onOpenClaim(data: { dossierId: string }): void;
  onSortChange(
    sort: { key: string[]; order: 'ascend' | 'descend' } | null
  ): void;
}

const ClaimsListComponent: React.FC<Props> = ({
  data,
  loading,
  onOpenClaim,
  onSortChange,
}) => {
  const { messages } = React.useContext(Locale);

  const columns = React.useMemo(
    () => [
      {
        key: 'client_dossier_id',
        dataIndex: 'client_dossier_id',
        title: messages['claims.hub.columns.client_dossier_id'],
        fixed: 'left' as const,
      },
      {
        key: 'dossier_name',
        dataIndex: 'dossier_name',
        title: messages['claims.hub.columns.dossier_name'],
        fixed: 'left' as const,
        sorter: true,
        render: (value, record) => {
          return (
            <Button
              type="link"
              onClick={() => {
                onOpenClaim({ dossierId: record.dossier_id });
              }}
            >
              {value}
            </Button>
          );
        },
      },
      {
        key: 'age',
        dataIndex: ['data', 'birth_date'],
        title: messages['claims.hub.columns.birth_date'],
        render: (value: string) => {
          return dayjs().diff(dayjs(value), 'years');
        },
      },
      {
        key: 'primaryDiagnosis',
        dataIndex: ['data', 'diagnosis_code'],
        title: messages['claims.hub.columns.primary_diagnosis'],
        sorter: true,
      },
      {
        key: 'disabilityDate',
        dataIndex: ['data', 'disability_begin_date'],
        title: messages['claims.hub.columns.date_of_disability'],
        render: (value: string) => {
          return dayjs(value).format(PRETTY_DATE_FORMAT);
        },
        sorter: true,
      },
      {
        key: 'intakeDate',
        dataIndex: ['data', 'cid_date'],
        title: messages['claims.hub.columns.intake_date'],
        render: (value: string) => {
          return dayjs(value).format(PRETTY_DATE_FORMAT);
        },
        sorter: true,
        defaultSortOrder: 'descend' as const,
      },
      {
        key: 'status',
        dataIndex: ['data', 'claim_settlement_status_date'],
        title: messages['claims.hub.columns.status'],
        render: (value: string) => <ClaimStatusComponent claimStatus={value} />,
      },
      {
        key: 'assignee',
        dataIndex: 'assignee',
        title: messages['claims.hub.columns.assignee'],
      },
      {
        key: 'manager',
        dataIndex: 'manager',
        title: messages['claims.hub.columns.manager'],
      },
    ],
    [messages, onOpenClaim]
  );

  const handleTableChange = React.useCallback(
    (a, b, sorter) => {
      if (!sorter.column) {
        onSortChange(null);
        return;
      }

      onSortChange({
        key: sorter.column.dataIndex,
        order: sorter.order,
      });
    },
    [onSortChange]
  );

  return (
    <Spin spinning={loading}>
      <Table<Claim>
        rowKey="dossier_id"
        dataSource={data}
        columns={columns}
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
      />
    </Spin>
  );
};

export default ClaimsListComponent;
