import { setIn } from 'timm';
import { call } from 'typed-redux-saga';
import {
  AsyncActionState,
  asyncActionStateMatchers,
  invoke,
  setupSlice,
} from '@owl-frontend/redux';
import claimApiClient from '../../../api/claims/client';
import {
  Claim,
  ClaimDatapoint,
  ClaimSummary,
} from '../../../api/claims/interface';

export interface ClaimOverviewState {
  claimMetadata: {
    [dossier_id: string]: Claim;
  };
  claimSummary: {
    [dossier_id: string]: ClaimSummary[];
  };
  claimDatapoints: {
    [dossier_id: string]: {
      [datapointType: string]: {
        datapoints: ClaimDatapoint[];
        total_count: number;
      };
    };
  };
  actions: {
    fetchClaim: AsyncActionState<Claim>;
    fetchClaimSummary: AsyncActionState<ClaimSummary[]>;
    fetchClaimDatapointsByType: AsyncActionState<{
      datapoints: ClaimDatapoint[];
      total_count: number;
      dp_type: string;
    }>;
    fetchClaimProviders: AsyncActionState<{
      datapoints: ClaimDatapoint[];
      total_count: number;
      dp_type: string;
    }>;
  };
}

const initialState: ClaimOverviewState = {
  claimMetadata: {},
  claimSummary: {},
  claimDatapoints: {},
  actions: {
    fetchClaim: { status: 'uninitialized' },
    fetchClaimSummary: { status: 'uninitialized' },
    fetchClaimDatapointsByType: { status: 'uninitialized' },
    fetchClaimProviders: { status: 'uninitialized' },
  },
};

export const FETCH_DATAPOINTS_LIMIT = 10;
function* fetchClaimDatapointsByType(action: {
  payload: {
    dossierId: string;
    datapointType: string;
    offset: number;
  };
}) {
  return yield* call(claimApiClient.fetchClaimDatapointByType, {
    ...action.payload,
    limit: FETCH_DATAPOINTS_LIMIT,
  });
}

function* fetchClaimProviders(action: {
  payload: {
    dossierId: string;
  };
}) {
  return yield* call(claimApiClient.fetchClaimDatapointByType, {
    dossierId: action.payload.dossierId,
    datapointType: 'providers',
    offset: 0,
    limit: 90_000,
  });
}

const slice = setupSlice('claim-overview', initialState)
  .addAsyncSagas({
    fetchClaim: invoke(claimApiClient.fetchClaim),
    fetchClaimSummary: invoke(claimApiClient.fetchClaimSummary),
    fetchClaimDatapointsByType,
    fetchClaimProviders,
  })
  .addReducers({
    'fetchClaim/fulfilled': (state, action) => {
      state.claimMetadata[action.meta.arg.dossierId] = action.payload;
    },
    'fetchClaimSummary/fulfilled': (state, action) => {
      state.claimSummary[action.meta.arg.dossierId] = action.payload;
    },
    'fetchClaimDatapointsByType/fulfilled': (state, action) => {
      state.claimDatapoints = setIn(
        state.claimDatapoints,
        [action.meta.arg.dossierId, action.meta.arg.datapointType],
        action.payload
      ) as typeof state.claimDatapoints;
    },
    'fetchClaimProviders/fulfilled': (state, action) => {
      state.claimDatapoints = setIn(
        state.claimDatapoints,
        [action.meta.arg.dossierId, 'providers'],
        action.payload
      ) as typeof state.claimDatapoints;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
