import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import { useIntl } from 'react-intl';
import { Breadcrumb, Card, GetProps, Input, Layout, Menu } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { PRETTY_DATE_FORMAT } from '@owl-frontend/components';
import { Document } from '../../../api/files/interface';
import { Locale } from '../../../context/AppProvider';
import { ClaimDetailsContext } from '../ClaimOverviewContainer';
import { formatString } from '../Utils';
import styles from './ClaimFileDetailsContainer.module.scss';
import ClaimFileViewerContainer from './ClaimFileViewerContainer';

const { Search } = Input;
interface Props {
  documents: Document[];
  setSelectedDocument(document: Document | null): void;
  selectedDocument: Document | null;
  totalPages: number;
}

const ClaimFileDetailsContainer: React.FC<Props> = ({
  documents,
  setSelectedDocument,
  selectedDocument,
  totalPages,
}) => {
  const intl = useIntl();
  const { claimMetadata, files } = React.useContext(ClaimDetailsContext);
  const { messages } = React.useContext(Locale);
  const [fileViewerPage, setFileViewerPage] = React.useState<number>(
    selectedDocument ? selectedDocument.start_page : 1
  );
  const [searchValue, setSearchValue] = React.useState('');

  const fuse = React.useMemo(() => {
    return new Fuse(documents, {
      isCaseSensitive: false,
      useExtendedSearch: true,
      keys: ['document_type'],
    });
  }, [documents]);

  type SearchProps = GetProps<typeof Input.Search>;
  const onSearch: SearchProps['onSearch'] = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const filteredDocuments = React.useMemo(() => {
    if (!searchValue || searchValue.trim() === '') {
      return documents;
    }
    return fuse.search(`'${searchValue.trim()}`).map((result) => result.item);
  }, [searchValue, fuse, documents]);

  const setSelectedPage = useCallback(
    (page: number) => {
      setFileViewerPage(page);

      if (!documents) {
        setSelectedDocument(null);
        return;
      }

      const foundDocument = documents.find(
        (document) => document.start_page <= page && document.end_page >= page
      );
      const newlySelectedDocument = foundDocument ? foundDocument : null;
      setSelectedDocument(newlySelectedDocument);
    },
    [documents, setSelectedDocument]
  );

  const menuItems = useMemo(
    () =>
      filteredDocuments?.map((document) => ({
        key: document.document_id,
        label: (
          <div className={styles.item}>
            <Text strong>{formatString(document.document_type)}</Text>
            <br />
            <Text className={styles.documentInfo} type="secondary">
              {dayjs(document.extracted_date).format(PRETTY_DATE_FORMAT)}
              {document.start_page === document.end_page
                ? intl.formatMessage(
                    {
                      id: 'claim_overview.files.documents_details.document_list_info.page',
                    },
                    { page: document.start_page }
                  )
                : intl.formatMessage(
                    {
                      id: 'claim_overview.files.documents_details.document_list_info.pages',
                    },
                    { start: document.start_page, end: document.end_page }
                  )}
            </Text>
          </div>
        ),
        onClick: () => setSelectedPage(document.start_page),
        className: styles.menuItem,
      })),
    [filteredDocuments, intl, setSelectedPage]
  );

  return (
    <Layout className={styles.modalContainer}>
      <Breadcrumb
        className={styles.breadcrumb}
        items={[
          {
            title: `${claimMetadata?.dossier_name} ${claimMetadata?.client_dossier_id}`,
          },
          {
            title: `${
              selectedDocument
                ? formatString(selectedDocument.document_type)
                : ''
            } - ${selectedDocument?.file_id}`,
          },
        ]}
      />
      <Layout>
        <Layout.Sider theme="light" className={styles.sider}>
          <Title className={styles.menuTitle} level={5}>
            {messages['claim_overview.files.title']}
          </Title>
          <Search
            className={styles.search}
            placeholder={
              messages['claim_overview.files.documents_details.search_bar']
            }
            onSearch={onSearch}
          />
          <Menu
            mode="inline"
            selectedKeys={[selectedDocument?.document_id || '']}
            items={menuItems}
          />
        </Layout.Sider>
        <Layout.Content className={styles.contentContainer}>
          {selectedDocument && (
            <>
              {selectedDocument.description && (
                <div className={styles.extractionContainer}>
                  <Card
                    title={
                      messages['claim_overview.files.documents_details.summary']
                    }
                    type="inner"
                  >
                    {selectedDocument.description}
                  </Card>
                </div>
              )}
              <div className={styles.fileViewerContainer}>
                <ClaimFileViewerContainer
                  fetchFileContent={files?.filesHookHandler.fetchFileContents}
                  downloadFile={files?.filesHookHandler.downloadFile}
                  fileId={selectedDocument.file_id}
                  currPage={fileViewerPage}
                  setSelectedPage={setSelectedPage}
                  totalPages={totalPages}
                />
              </div>
            </>
          )}
        </Layout.Content>
      </Layout>
    </Layout>
  );
};

export default ClaimFileDetailsContainer;
