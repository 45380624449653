import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';

import useUsersHook from '../AdminPanelContainer/UsersContainer/useUsersHook';
import { generatePath, RouteDefs } from '../Routing/Routing';
import useClaimsHook from './useClaimsHook';
import styles from './ClaimsContainer.module.scss';
import ClaimsListComponent from './ClaimsListComponent/ClaimsListComponent';
import ClaimsListFilterComponent from './ClaimsListFilterComponent/ClaimsListFilterComponent';

const ClaimsContainer: React.FC = () => {
  const navigate = useNavigate();
  const [
    {
      claims,
      filters,
      sort,
      actions: { fetchClaims },
    },
    handler,
  ] = useClaimsHook();

  const [
    {
      users,
      actions: { users: fetchUsers },
    },
    usersHookHandler,
  ] = useUsersHook();

  const onOpenClaim = React.useCallback(
    (data: { dossierId: string }) => {
      navigate(
        generatePath(RouteDefs.ClaimOverview, { dossierId: data.dossierId })
      );
    },
    [navigate]
  );

  React.useEffect(() => {
    handler.fetchClaims({ filters, sort });
  }, [handler, filters, sort]);

  React.useEffect(() => {
    usersHookHandler.fetchUsers();
  }, [usersHookHandler]);

  if (fetchUsers.status === 'pending') {
    return null;
  }

  return (
    <Layout className={styles.content}>
      <ClaimsListFilterComponent
        users={users}
        filters={filters}
        onFilterChange={handler.onFilterChange}
      />
      <ClaimsListComponent
        data={claims}
        loading={fetchClaims.status === 'pending'}
        onOpenClaim={onOpenClaim}
        onSortChange={handler.onSortChange}
      />
    </Layout>
  );
};

export default ClaimsContainer;
