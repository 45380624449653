import React from 'react';
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Button, Card, Spin, Typography } from 'antd';
import { useEffectWithPrev } from '@owl-frontend/components';
import { DocumentCitations } from '../../../api/files/interface';
import { Locale, SessionContext } from '../../../context/AppProvider';
import useAskOwlHook from './useAskOwlHook';
import styles from './AskOwlContainer.module.scss';

const CITATION = /^ns\d+:/;

interface Props {
  dossierId: string;
  query: string;
  selectedAnswer?: {
    history_id?: string;
    answer?: string;
    citations?: DocumentCitations;
    is_saved?: boolean;
  };
}

const SearchAnswerContainer: React.FC<Props> = ({
  dossierId,
  query,
  selectedAnswer,
}) => {
  const { messages } = React.useContext(Locale);
  const session = React.useContext(SessionContext);
  const [{ answer, savedHistoryId, actions }, askOwlHandler] =
    useAskOwlHook(dossierId);
  const [isSaved, setIsSaved] = React.useState<boolean | null>(
    selectedAnswer?.is_saved ?? null
  );
  let citationCounter = 0;
  const components: Components = {
    code: ({ children }) => {
      // Temporary to show the citation where it's formatted as `ns<number>`
      if (typeof children === 'string' && CITATION.test(children)) {
        citationCounter += 1;
        return <Button type="link">{citationCounter}</Button>;
      }
      return <code>{children}</code>;
    },
  };

  React.useEffect(() => {
    if (!selectedAnswer) {
      return;
    }
    setIsSaved(selectedAnswer?.is_saved ?? null);
  }, [selectedAnswer, selectedAnswer?.is_saved]);

  const handleSaveToggle = () => {
    const updatedSavedStatus = !isSaved;
    setIsSaved(updatedSavedStatus);
    askOwlHandler.saveQuestion({
      historyId: selectedAnswer?.history_id ?? savedHistoryId,
      isSaved: updatedSavedStatus,
    });
  };

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (actions.search.status === 'fulfilled') {
        askOwlHandler.saveSearchHistory({
          query,
          answer,
          citations: {},
          email: session?.current?.email || '',
        });
      }
    },
    [actions.search.status, askOwlHandler, answer, query, session]
  );

  if (actions.search.status === 'rejected') {
    return (
      <Card type="inner" title={query} className={styles.cardTitle}>
        {messages['claim_overview.ask_owl.error']}
      </Card>
    );
  }

  return (
    <>
      <Card type="inner" title={query} className={styles.cardTitle}>
        {!answer && !selectedAnswer?.answer ? (
          <Spin />
        ) : (
          <div data-color-mode="light">
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              components={components}
              className={styles.markdown}
            >
              {selectedAnswer?.answer ?? answer}
            </ReactMarkdown>
          </div>
        )}
      </Card>
      {(actions.search.status === 'fulfilled' ||
        selectedAnswer?.is_saved != null) && (
        <div className={styles.saveText}>
          {isSaved ? (
            <>
              <CheckCircleOutlined
                className={styles.savedIcon}
                onClick={handleSaveToggle}
              />
              <Typography.Text className={styles.savedIcon}>
                {messages['claim_overview.ask_owl.saved_questions.saved']}
              </Typography.Text>
            </>
          ) : (
            <>
              <PlusCircleOutlined
                className={styles.unsavedIcon}
                onClick={handleSaveToggle}
              />
              <Typography.Text className={styles.unsavedIcon}>
                {messages['claim_overview.ask_owl.saved_questions.unsaved']}
              </Typography.Text>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default SearchAnswerContainer;
