import React from 'react';
import { useDispatch } from 'react-redux';
import { Claim } from '../../api/claims/interface';
import { StoreState, useSelector } from '../../data/store';
import { actions } from './data/logic';

interface ClaimOverviewHookState {
  claimMetadata?: Claim;
  actions: StoreState['claim-overview']['actions'];
}

interface ClaimOverviewHookHandler {
  fetchClaim(): void;
}

const useClaimOverviewHook = (
  dossierId: string
): [ClaimOverviewHookState, ClaimOverviewHookHandler] => {
  const { claimMetadata, actions: claimOverviewActions } = useSelector(
    (s: StoreState) => s['claim-overview']
  );
  const dispatch = useDispatch();

  const handler = React.useMemo(
    () => ({
      fetchClaim: () => {
        dispatch(actions.fetchClaim({ dossierId }));
      },
    }),
    [dossierId]
  );

  return [
    {
      claimMetadata: claimMetadata[dossierId],
      actions: claimOverviewActions,
    },
    handler,
  ];
};

export default useClaimOverviewHook;
