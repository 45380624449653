import { eventChannel } from 'redux-saga';
import { createLogger } from '@owl-lib/logger';

import { deserialize, fetchWrapper } from '../fetch';
import { DocumentCitations } from '../files/interface';
import { SearchHistory } from './interface';

const logger = createLogger(__filename);

export const TIMEOUT_MS = 60_000;

const searchApiClient = {
  search: async (data: { dossierId: string; query: string }) => {
    const { dossierId, query } = data;
    const url = new URL(`/app/v1/dossiers/${dossierId}/search`, API_BASE_URL);
    logger.trace('searchApiClient.search()');
    return eventChannel((emit) => {
      (async () => {
        try {
          const response = await fetchWrapper(url.href, {
            method: 'POST',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ query }),
          });

          if (!response.ok) {
            emit({ type: 'error', error: `HTTP error: ${response.status}` });
            return;
          }

          logger.trace('searchApiClient.search(): channel listening');

          const reader = response.body?.getReader();
          const decoder = new TextDecoder();

          if (!reader) {
            emit({
              type: 'error',
              error: 'Error in reader',
            });
            return;
          }

          let done = false;
          while (!done) {
            const result = await Promise.race([
              reader.read(),
              new Promise<ReadableStreamReadResult<Uint8Array>>(
                (_resolve, reject) => {
                  setTimeout(() => {
                    reject(new Error('Timeout while reading stream'));
                  }, TIMEOUT_MS);
                }
              ),
            ]).catch((error) => {
              emit({ type: 'error', error: error.message });
              done = true;
              return null;
            });

            if (!result) {
              break;
            }

            done = result.done;

            if (result.value) {
              const chunk = decoder.decode(result.value, { stream: true });
              const line = chunk.replace(/\\n\\n/g, '\n\n');
              emit({ type: 'message', line });
            }
          }
        } catch (error) {
          emit({ type: 'error', error: error.message });
        } finally {
          emit({ type: 'done' });
        }
      })();
      return () => {
        logger.trace('searchApiClient.search(): channel closed');
      };
    });
  },
  fetchHistory: async (data: {
    dossierId: string;
  }): Promise<SearchHistory[]> => {
    const url = new URL(
      `/app/v1/dossiers/${data.dossierId}/search/history`,
      API_BASE_URL
    );

    logger.trace('searchApiClient.fetchHistory()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
  saveHistory: async (data: {
    dossierId: string;
    email: string;
    query: string;
    answer: string;
    citations: DocumentCitations;
  }): Promise<{ history_id: string }> => {
    const { dossierId, email, query, answer, citations } = data;
    const url = new URL(
      `/app/v1/dossiers/${dossierId}/search/history`,
      API_BASE_URL
    );
    url.searchParams.set('email', email);
    logger.trace('searchApiClient.saveHistory()');
    return deserialize(
      await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({
          query,
          answer,
          citations,
        }),
      })
    );
  },
  saveQuestion: async (data: {
    dossierId: string;
    historyId: string;
    isSaved: boolean;
  }) => {
    const { dossierId, historyId, isSaved } = data;
    const url = new URL(
      `/app/v1/dossiers/${dossierId}/search/history/${historyId}`,
      API_BASE_URL
    );
    url.searchParams.set('is_saved', isSaved.toString());

    logger.trace('searchApiClient.saveQuestion()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'PUT', credentials: 'include' })
    );
  },
};

export default searchApiClient;
