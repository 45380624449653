import React, { MutableRefObject } from 'react';
import dayjs from 'dayjs';
import { Markup } from 'react-render-markup';
import { Button, Card } from 'antd';
import { PRETTY_DATE_FORMAT } from '@owl-frontend/components';
import { Activity } from '../../../api/activities/interface';
import { Locale } from '../../../context/AppProvider';
import { formatString } from '../Utils';
import styles from './ClaimActivitiesContainer.module.scss';
const MAX_HEIGHT = 290;

interface ActivityFilterProps {
  activity: Activity;
  activityRefs: MutableRefObject<Record<string, HTMLDivElement | null>>;
  setSelectedActivity: (activity: Activity) => void;
}

const ActivityCard: React.FC<ActivityFilterProps> = ({
  activity,
  activityRefs,
  setSelectedActivity,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isTooLarge, setIsTooLarge] = React.useState(false);
  const { messages } = React.useContext(Locale);

  return (
    <Card
      title={formatString(activity.activity_type)}
      type="inner"
      extra={dayjs(activity.created_at).format(PRETTY_DATE_FORMAT)}
      className={styles.activityCard}
      ref={(elem) => (activityRefs.current[activity.activity_id] = elem)}
      onClick={() => setSelectedActivity(activity)}
    >
      <div
        ref={(contentRef) => {
          if (contentRef && contentRef.scrollHeight > MAX_HEIGHT) {
            setIsTooLarge(true);
          }
        }}
        style={{
          maxHeight: isExpanded ? 'none' : `${MAX_HEIGHT}px`,
          overflow: isExpanded ? 'visible' : 'hidden',
        }}
      >
        <div
          style={{
            background:
              !isExpanded && isTooLarge
                ? 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))'
                : 'none',
            height: !isExpanded && isTooLarge ? '150px' : '0px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            pointerEvents: 'none',
          }}
        ></div>
        <Markup markup={activity.description} />
      </div>
      {isTooLarge && (
        <div className={styles.expandButton}>
          <Button
            style={{ padding: '0px' }}
            type="link"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded
              ? messages['claim_overview.activities.activity_expand.collapse']
              : messages['claim_overview.activities.activity_expand.expand']}
          </Button>
        </div>
      )}
    </Card>
  );
};

export default ActivityCard;
