import { createLogger } from '@owl-lib/logger';
import { deserialize, fetchWrapper } from '../fetch';
import type { Claim, ClaimDatapoint, ClaimSummary } from './interface';
const logger = createLogger(__filename);

const claimsApiClient = {
  fetch: async (data: {
    filters: string;
    sort?: {
      sort_by: string[];
      is_descending: boolean;
    };
  }): Promise<Claim[]> => {
    const url = new URL('/app/v1/dossiers', API_BASE_URL);

    url.searchParams.set('filters', data.filters);

    if (data.sort) {
      const sortParams = JSON.stringify({
        sort_by: data.sort.sort_by,
        is_descending: data.sort.is_descending,
      });
      url.searchParams.set('sort_parameters', sortParams);
    }

    logger.trace('claimsApiClient.fetch()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
  fetchClaim: async (data: { dossierId: string }): Promise<Claim> => {
    const url = new URL(`/app/v1/dossiers/${data.dossierId}`, API_BASE_URL);

    logger.trace('claimsApiClient.fetch()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
  fetchClaimSummary: async (data: {
    dossierId: string;
  }): Promise<ClaimSummary[]> => {
    const url = new URL(
      `/app/v1/dossiers/${data.dossierId}/summaries`,
      API_BASE_URL
    );

    logger.trace('claimsApiClient.fetchClaimSummary()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
  fetchClaimDatapointByType: async (data: {
    dossierId: string;
    datapointType: string;
    offset?: number;
    limit?: number;
  }): Promise<{
    dp_type: string;
    datapoints: ClaimDatapoint[];
    total_count: number;
  }> => {
    const url = new URL(
      `/app/v1/dossiers/${data.dossierId}/datapoints`,
      API_BASE_URL
    );

    url.searchParams.set('datapoint_type', data.datapointType);
    url.searchParams.set('offset', `${data.offset ?? 0}`);

    if (data.limit) {
      url.searchParams.set('limit', `${data.limit}`);
    }

    logger.trace('claimsApiClient.fetchClaimSummary()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
};

export default claimsApiClient;
