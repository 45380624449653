import { call, delay, put, race, take } from 'typed-redux-saga';
import {
  AsyncActionState,
  asyncActionStateMatchers,
  setupSlice,
  invoke,
} from '@owl-frontend/redux';
import searchApiClient, { TIMEOUT_MS } from '../../api/search/client';
import { SearchHistory } from '../../api/search/interface';

export interface SearchState {
  searchHistory: SearchHistory[];
  answer: string;
  savedHistoryId: string | null;
  actions: {
    searchHistory: AsyncActionState;
    saveHistory: AsyncActionState;
    saveQuestion: AsyncActionState;
    search: AsyncActionState;
  };
}
interface SearchEvent {
  type: 'message' | 'error' | 'done';
  line?: string;
  error?: string;
}

const initialState: SearchState = {
  searchHistory: [],
  answer: '',
  savedHistoryId: null,
  actions: {
    search: { status: 'uninitialized' },
    searchHistory: { status: 'uninitialized' },
    saveHistory: { status: 'uninitialized' },
    saveQuestion: { status: 'uninitialized' },
  },
};

function* search(action: { payload: { dossierId: string; query: string } }) {
  yield* put({
    type: 'search/clearAnswer',
  });
  const channel = yield* call(searchApiClient.search, action.payload);
  try {
    while (true) {
      const { event, timeout } = yield* race({
        event: take(channel),
        timeout: delay(TIMEOUT_MS),
      });
      if (timeout) {
        yield* put({
          type: 'search/search/rejected',
          payload: { reason: 'Timeout occurred' },
          meta: { requestStatus: 'rejected' },
        });
        return;
      }
      if ((event as SearchEvent)?.type === 'message') {
        yield* put({
          type: 'search/addStreamedData',
          payload: (event as SearchEvent)?.line,
        });
      }
      if ((event as SearchEvent)?.type === 'done') {
        break;
      }
    }
  } finally {
    channel.close();
  }
}

const slice = setupSlice('search', initialState)
  .addAsyncSagas({
    searchHistory: invoke(searchApiClient.fetchHistory),
    saveHistory: invoke(searchApiClient.saveHistory),
    saveQuestion: invoke(searchApiClient.saveQuestion),
  })
  .addAsyncSagas(
    {
      search,
    },
    {
      timeoutLimit: TIMEOUT_MS,
    }
  )
  .addReducers({
    'searchHistory/fulfilled': (state, action) => {
      state.searchHistory = action.payload;
    },
    'saveHistory/fulfilled': (state, action) => {
      state.savedHistoryId = action.payload.history_id;
    },
  })
  .addReducers({
    addStreamedData(state, action) {
      state.answer += `${action.payload}`;
    },
    clearAnswer(state) {
      state.answer = '';
      state.savedHistoryId = null;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
