// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../node_modules/sass-loader/dist/cjs.js!../../node_modules/antd/dist/reset.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  line-height: normal;\n}\n\nh1, h2, h3, h4, h5, h6, p {\n  margin-bottom: 0;\n}", "",{"version":3,"sources":["webpack://./src/styles/index.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;AADF;;AAKA;EACE,gBAAA;AAFF","sourcesContent":["// TODO: core app layout styles.\n@import 'antd/dist/reset.css';\n\nbody {\n  line-height: normal;\n}\n\n// reset\nh1, h2, h3, h4, h5, h6, p {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
