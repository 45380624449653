import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Activity } from '../../../api/activities/interface';
import { actions as activitiesSliceActions } from '../../../data/activities/logic';
import { StoreState } from '../../../data/store';

export interface ActivitiesHookState {
  activities: { [dossier_id: string]: Activity[] } | never[];
  actions: StoreState['activities']['actions'];
}

interface ActivitiesHookHandler {
  fetchActivities(dossier_id: string): void;
}

const useActivitiesHook = (): [ActivitiesHookState, ActivitiesHookHandler] => {
  const dispatch = useDispatch();
  const { activities = {}, actions } = useSelector(
    (s: StoreState) => s['activities']
  );

  const handler = React.useMemo(
    () => ({
      fetchActivities: (dossier_id: string) => {
        dispatch(activitiesSliceActions.fetchActivities(dossier_id));
      },
    }),
    []
  );

  return [{ activities, actions }, handler];
};

export default useActivitiesHook;
