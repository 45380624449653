import React from 'react';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';

const DefaultRedirect = ({ path }: { path: string }) => {
  const navigate = useNavigate();
  const redirectTo = path;
  const location = useLocation();
  const isCurrentPage = useMatch(redirectTo);
  // TODO: check for valid route. Or not, and display 404 page
  React.useEffect(() => {
    if (!isCurrentPage) {
      const queryString = location.search;
      navigate(`${path}${queryString}`, { replace: true });
    }
  }, [isCurrentPage, navigate, path, location.search]);
  return <React.Fragment />;
};

export default DefaultRedirect;
