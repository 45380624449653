import React from 'react';
import { useDispatch } from 'react-redux';
import { ClaimSummary } from '../../../api/claims/interface';
import { StoreState, useSelector } from '../../../data/store';
import { actions as claimOverviewActions } from '../data/logic';

export interface UseClaimSummaryHookState {
  claimSummary: ClaimSummary[];
  actions: StoreState['claim-overview']['actions'];
}

export interface UseClaimSummaryHookHandler {
  fetchClaimSummary(): void;
}

const useClaimSummaryHook = (
  dossierId: string
): [UseClaimSummaryHookState, UseClaimSummaryHookHandler] => {
  const dispatch = useDispatch();
  const { claimSummary, actions } = useSelector(
    (s: StoreState) => s['claim-overview']
  );

  const handler = React.useMemo(
    () => ({
      fetchClaimSummary: () => {
        dispatch(claimOverviewActions.fetchClaimSummary({ dossierId }));
      },
    }),
    [dossierId]
  );

  return [
    {
      claimSummary: claimSummary[dossierId],
      actions,
    },
    handler,
  ];
};

export default useClaimSummaryHook;
