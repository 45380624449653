import { createLogger } from '@owl-lib/logger';
import { deserialize, fetchWrapper } from '../fetch';
import type { ClaimFile } from './interface';
const logger = createLogger(__filename);

export const FILE_CONTENT_TIMEOUT_LIMIT = 60_000;
const filesApiClient = {
  fetch: async (dossier_id: string): Promise<ClaimFile[]> => {
    const url = new URL(`/app/v1/dossiers/${dossier_id}/files`, API_BASE_URL);
    logger.trace('filesApiClient.files()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
  fetchFilePage: async (data: {
    fileId: string;
    pageStart: number;
  }): Promise<{ url: string }> => {
    const { fileId, pageStart } = data;
    const url = new URL(
      `/app/v1/files/${fileId}/page/${pageStart}/contents`,
      API_BASE_URL
    );

    logger.trace('filesApiClient.fetchFilePage()');
    return deserialize(
      await fetchWrapper(
        url.href,
        { method: 'GET', credentials: 'include' },
        FILE_CONTENT_TIMEOUT_LIMIT
      )
    );
  },
  downloadFile: async (fileId: string): Promise<{ url: string }> => {
    const url = new URL(`/app/v1/files/${fileId}/contents`, API_BASE_URL);

    logger.trace('filesApiClient.downloadFile()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
};

export default filesApiClient;
