import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentCitations } from '../../../api/files/interface';
import { SearchHistory } from '../../../api/search/interface';
import { actions as searchSliceActions } from '../../../data/search/logic';
import { StoreState } from '../../../data/store';

export interface AskOwlHookState {
  searchHistory: SearchHistory[];
  localSearchPrompt?: string;
  answer: string;
  savedHistoryId: string;
  actions: StoreState['search']['actions'];
}

interface AskOwlHookHandler {
  search(query: string): void;
  fetchSearchHistory(dossierId: string): void;
  setLocalSearchPrompt(query: string): void;
  saveSearchHistory(data: {
    email: string;
    query: string;
    answer: string;
    citations: DocumentCitations;
  }): void;
  saveQuestion(data: { historyId: string; isSaved: boolean });
}

const useAskOwlHook = (
  dossierId: string
): [AskOwlHookState, AskOwlHookHandler] => {
  const dispatch = useDispatch();
  const {
    searchHistory = [],
    answer,
    savedHistoryId,
    actions,
  } = useSelector((s: StoreState) => s['search']);
  const [localSearchPrompt, setLocalSearchPrompt] = React.useState<
    string | null
  >(null);

  const handler = React.useMemo(
    () => ({
      search: (query: string) => {
        dispatch(searchSliceActions.search({ dossierId, query }));
      },
      fetchSearchHistory: () => {
        dispatch(searchSliceActions.searchHistory({ dossierId }));
      },
      setLocalSearchPrompt: (query: string) => {
        setLocalSearchPrompt(query);
      },
      saveSearchHistory: (data: {
        email: string;
        query: string;
        answer: string;
        citations: DocumentCitations;
      }) => {
        dispatch(searchSliceActions.saveHistory({ dossierId, ...data }));
      },
      saveQuestion: (data: { historyId: string; isSaved: boolean }) => {
        const { historyId, isSaved } = data;
        dispatch(
          searchSliceActions.saveQuestion({ dossierId, historyId, isSaved })
        );
      },
    }),
    [dossierId]
  );

  return [
    { searchHistory, answer, savedHistoryId, actions, localSearchPrompt },
    handler,
  ];
};

export default useAskOwlHook;
