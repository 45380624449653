import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Layout, Input, Typography, Menu } from 'antd';
import { useEffectWithPrev } from '@owl-frontend/components';
import { UnionExpand } from '@owl-lib/type-util';
import { SearchHistory } from '../../../api/search/interface';
import OwlLogo from '../../../assets/app-spinner.svg';
import { Locale } from '../../../context/AppProvider';
import { ClaimDetailsContext, Params } from '../ClaimOverviewContainer';
import useAskOwlHook from './useAskOwlHook';
import styles from './AskOwlContainer.module.scss';
import SearchAnswerContainer from './SearchAnswerContainer';

const AskOwlContainer: React.FC = () => {
  const intl = useIntl();
  const { messages } = React.useContext(Locale);
  const { dossierId } = useParams() as UnionExpand<Params>;
  const { claimMetadata, askOwl: { localSearchPrompt, searchHistory } = {} } =
    React.useContext(ClaimDetailsContext);
  const [{ savedHistoryId, actions }, askOwlHandler] = useAskOwlHook(dossierId);

  const [queryTitle, setQueryTitle] = React.useState<string | null>(null);
  const [selectedHistory, setSelectedHistory] =
    React.useState<SearchHistory | null>(null);
  const [hasSearched, setHasSearched] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const menuItems = React.useMemo(() => {
    return searchHistory?.map((history) => ({
      key: history.history_id,
      label: history.query,
      ...history,
    }));
  }, [searchHistory]);

  const handleHistorySelection = React.useCallback(
    (key: string) => {
      if (!searchHistory) {
        return;
      }

      const history = searchHistory.find((item) => item.history_id === key);
      if (!history) {
        return;
      }
      setSelectedHistory(history);
      setQueryTitle(history?.query);
      return history;
    },
    [searchHistory]
  );

  const onSearch = React.useCallback(
    (prompt) => {
      setSearchValue('');
      setQueryTitle(prompt);
      setSelectedHistory(null);
      setHasSearched(true);
      askOwlHandler.search(prompt);
    },
    [askOwlHandler, setQueryTitle]
  );

  React.useEffect(() => {
    if (!localSearchPrompt) {
      return;
    }
    setQueryTitle(localSearchPrompt);
    setSelectedHistory(null);
    setHasSearched(true);
    askOwlHandler.search(localSearchPrompt);
  }, [askOwlHandler, localSearchPrompt]);

  React.useEffect(() => {
    if (!searchHistory) {
      return;
    }
    if (searchHistory) {
      const historyIdToFind = selectedHistory?.history_id || savedHistoryId;

      if (historyIdToFind) {
        const updatedHistory = searchHistory.find(
          (history) => history.history_id === historyIdToFind
        );

        if (updatedHistory) {
          setSelectedHistory(updatedHistory);
        }
      }
    }
  }, [searchHistory, selectedHistory, savedHistoryId]);

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (actions.saveHistory.status === 'fulfilled') {
        askOwlHandler.fetchSearchHistory(dossierId);
      }
    },
    [actions.saveHistory.status, askOwlHandler, dossierId]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (actions.saveQuestion.status === 'fulfilled') {
        askOwlHandler.fetchSearchHistory(dossierId);
      }
    },
    [actions.saveQuestion.status, askOwlHandler, dossierId]
  );

  if (!hasSearched && searchHistory?.length === 0) {
    return (
      <div className={styles.askOwlContainer}>
        <OwlLogo className={styles.owlLogo} />
        <Typography.Title level={4}>
          {intl.formatMessage(
            {
              id: 'claim_overview.ask_owl.prompt',
            },
            { claimantName: claimMetadata?.data?.first_nm }
          )}
        </Typography.Title>
        <Typography.Text>
          {messages['claim_overview.ask_owl.subtitle']}
        </Typography.Text>
        <Input.Search
          placeholder={intl.formatMessage(
            {
              id: 'claim_overview.ask_owl.placeholder',
            },
            { claimantName: claimMetadata?.data?.first_nm }
          )}
          enterButton={<ArrowRightOutlined />}
          onSearch={onSearch}
          className={styles.searchBar}
        />
      </div>
    );
  }

  return (
    <Layout className={styles.layout}>
      <Layout.Sider theme="light" width="300" className={styles.sider}>
        {menuItems && menuItems?.filter((item) => item.is_saved).length > 0 && (
          <>
            <Typography.Text strong>
              {messages['claim_overview.ask_owl.saved_questions.title']}
            </Typography.Text>
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={
                selectedHistory?.history_id ? [selectedHistory.history_id] : []
              }
              items={menuItems?.filter((item) => item.is_saved)}
              onSelect={(item) => {
                handleHistorySelection(item.key);
              }}
            />
          </>
        )}
        <Typography.Text strong>
          {messages['claim_overview.ask_owl.history']}
        </Typography.Text>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={
            selectedHistory?.history_id ? [selectedHistory.history_id] : []
          }
          items={menuItems?.filter((item) => !item.is_saved)}
          onSelect={(item) => {
            handleHistorySelection(item.key);
          }}
        />
      </Layout.Sider>
      <Layout.Content className={styles.layoutContent}>
        <OwlLogo className={styles.owlLogo} />
        <div className={styles.searchAnwerContainer}>
          <Input.Search
            value={searchValue}
            placeholder={intl.formatMessage(
              {
                id: 'claim_overview.ask_owl.prompt',
              },
              { claimantName: claimMetadata?.data?.first_nm }
            )}
            enterButton={<ArrowRightOutlined />}
            onSearch={onSearch}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {queryTitle && (
            <SearchAnswerContainer
              dossierId={dossierId}
              query={queryTitle}
              selectedAnswer={{
                answer: selectedHistory?.answer,
                citations: selectedHistory?.citations,
                history_id: selectedHistory?.history_id,
                is_saved: selectedHistory?.is_saved,
              }}
            />
          )}
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default AskOwlContainer;
