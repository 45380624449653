import {
  AsyncActionState,
  asyncActionStateMatchers,
  setupSlice,
  invoke,
} from '@owl-frontend/redux';
import activitiesApiClient from '../../api/activities/client';
import type { Activity } from '../../api/activities/interface';

export interface ActivitiesState {
  activities: {
    [dossier_id: string]: Activity[];
  };
  actions: {
    fetchActivities: AsyncActionState<Activity[]>;
  };
}

const initialState: ActivitiesState = {
  activities: {},
  actions: {
    fetchActivities: { status: 'uninitialized' },
  },
};

const slice = setupSlice('activities', initialState)
  .addAsyncSagas({
    fetchActivities: invoke(activitiesApiClient.fetchActivities),
  })
  .addReducers({
    'fetchActivities/fulfilled': (state, action) => {
      state.activities[action.meta.arg] = action.payload;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
