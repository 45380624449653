import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import AppProvider from './context/AppProvider';
import ReduxProvider from './context/ReduxProvider';
import store from './data/store';
import './styles/index.scss';

const container = document.getElementById('owl-frontend-app-platform-root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ReduxProvider reduxStore={store.reduxStore}>
      <Router>
        <AppProvider />
      </Router>
    </ReduxProvider>
  </React.StrictMode>
);
