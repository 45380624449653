import React from 'react';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { nanoid } from 'nanoid';
import { DatePicker } from 'antd';
import type {
  PickerProps as DatePickerAntdProps,
  RangePickerProps as DateRangeAntdProps,
} from 'antd/es/date-picker/generatePicker/interface';
import 'antd/es/date-picker/style/index';
import formStyles from '../../shared/Form.module.scss';
import type { FieldWrapperProps } from '../../shared/interface';
import styles from './DatePicker.module.scss';

export interface Props
  extends Omit<DatePickerAntdProps<Dayjs>, 'picker' | 'value'>,
    FieldWrapperProps {
  picker?: DatePickerAntdProps<Dayjs>['picker'];
  value?: string | Dayjs;
}

export interface RangeProps
  extends Omit<DateRangeAntdProps<Dayjs>, 'picker'>,
    FieldWrapperProps {
  picker?: DatePickerAntdProps<Dayjs>['picker'];
}

const DatePickerAntd: React.FC<Props> = ({
  id,
  name,
  label,
  trailer,
  value,
  ...datePickerProps
}) => {
  const [htmlForNanoid] = React.useState(nanoid);
  const htmlFor = id || (name ? `${name}-${htmlForNanoid}` : htmlForNanoid);
  return (
    <div className={formStyles.field}>
      {label && (
        <div className={formStyles.label}>
          <label htmlFor={htmlFor}>{label}</label>
        </div>
      )}
      <div className={formStyles.control}>
        <DatePicker
          id={htmlFor}
          {...datePickerProps}
          value={!value ? null : dayjs.isDayjs(value) ? value : dayjs(value)}
          className={`${styles.datePickerWrapper}`}
        />
        {trailer}
      </div>
    </div>
  );
};

const DateRangePickerAntd: React.FC<RangeProps> = ({
  id,
  name,
  label,
  trailer,
  ...dateRangeProps
}) => {
  const [htmlForNanoid] = React.useState(nanoid);
  const htmlFor = id || (name ? `${name}-${htmlForNanoid}` : htmlForNanoid);
  return (
    <div className={formStyles.field}>
      {label && (
        <div className={formStyles.label}>
          <label htmlFor={htmlFor as string}>{label}</label>
        </div>
      )}
      <div className={formStyles.control}>
        <DatePicker.RangePicker
          {...dateRangeProps}
          className={`${styles.datePickerWrapper}`}
        />
        {trailer}
      </div>
    </div>
  );
};

export { default as DatePickerAntdInterface } from 'rc-picker/lib/interface';
export { DatePickerAntd, DateRangePickerAntd };
