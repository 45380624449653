import React from 'react';
import { Button, GetProps, Input, Select } from 'antd';
import { Locale } from '../../../context/AppProvider';
import styles from './UsersContainer.module.scss';

const { Search } = Input;

interface UserFilterProps {
  setFilteredRole(type: string): void;
  setIsModalVisible(isModalVisible: boolean): void;
  setSearchValue(value: string): void;
}

const UserFilters: React.FC<UserFilterProps> = ({
  setFilteredRole,
  setIsModalVisible,
  setSearchValue,
}) => {
  const { messages } = React.useContext(Locale);

  type SearchProps = GetProps<typeof Input.Search>;
  const onSearch: SearchProps['onSearch'] = React.useCallback(
    (value: string) => {
      setSearchValue(value);
    },
    [setSearchValue]
  );
  return (
    <div className={`${styles.sharedContainer} ${styles.filtersContainer}`}>
      <div className={styles.searchItem}>
        <label className={styles.filterLabel}>
          {messages['users.filters.search.label']}
        </label>
        <Search
          placeholder={messages['users.filters.search.placeholder']}
          onSearch={onSearch}
        />
      </div>
      <div className={styles.filterItem}>
        <label className={styles.filterLabel}>
          {messages['users.filters.role.label']}
        </label>
        <Select
          onChange={(value) => setFilteredRole(value)}
          options={[
            {
              label: messages['users.filters.role.options.all'],
              value: '',
            },
            {
              label: messages['users.filters.role.options.assignee'],
              value: messages['users.filters.role.options.assignee'],
            },
            {
              label: messages['users.filters.role.options.manager'],
              value: messages['users.filters.role.options.manager'],
            },
          ]}
        />
      </div>
      <div className={styles.buttonItem}>
        <Button
          className={styles.sortButton}
          type="primary"
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          {messages['users.filters.buttons.add']}
        </Button>
      </div>
    </div>
  );
};

export default UserFilters;
