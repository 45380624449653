import React from 'react';
import { useDispatch } from 'react-redux';
import { ClaimDatapoint } from '../../../api/claims/interface';
import { StoreState, useSelector } from '../../../data/store';
import {
  ClaimOverviewState,
  actions as claimOverviewActions,
} from '../data/logic';

export interface UseClaimDatapointsHookState {
  claimDatapoints: {
    [datapointType: string]: {
      datapoints: ClaimDatapoint[];
      total_count: number;
    };
  };
  actions: ClaimOverviewState['actions'];
}

export interface UseClaimDatapointsHookHandler {
  fetchDatapointsByType(data: { datapointType: string; offset: number }): void;
  fetchProviders(): void;
}

const useClaimDatapointsHook = (
  dossierId: string
): [UseClaimDatapointsHookState, UseClaimDatapointsHookHandler] => {
  const dispatch = useDispatch();
  const { claimDatapoints, actions } = useSelector(
    (s: StoreState) => s['claim-overview']
  );

  const handler = React.useMemo(
    () => ({
      fetchDatapointsByType: (data: {
        datapointType: string;
        offset: number;
      }) => {
        dispatch(
          claimOverviewActions.fetchClaimDatapointsByType({
            dossierId,
            datapointType: data.datapointType,
            offset: data.offset,
          })
        );
      },
      fetchProviders: () => {
        dispatch(
          claimOverviewActions.fetchClaimProviders({
            dossierId,
          })
        );
      },
    }),
    [dossierId]
  );

  return [{ claimDatapoints: claimDatapoints[dossierId], actions }, handler];
};

export default useClaimDatapointsHook;
