import React from 'react';
import dayjs from 'dayjs';
import { Spin, Typography } from 'antd';
import { Locale } from '../../../context/AppProvider';
import { ClaimDetailsContext } from '../ClaimOverviewContainer';
import ClaimFileViewer from './ClaimFileViewer';
import styles from './ClaimFileViewer.module.scss';

const TTL_EXPIRY_SECONDS = 60;
const PAGE_RANGE = 3;
interface Props {
  fetchFileContent?(data: { fileId: string; pages: number[] }): void;
  downloadFile?(fileId: string);
  fileId: string;
  currPage: number;
  setSelectedPage(page: number): void;
  totalPages: number;
}

const ClaimFileViewerContainer: React.FC<Props> = ({
  fetchFileContent,
  downloadFile,
  fileId,
  currPage,
  setSelectedPage,
  totalPages,
}) => {
  const { messages } = React.useContext(Locale);
  const { files } = React.useContext(ClaimDetailsContext);
  const filesContent = files?.filesContent;
  const fetchFileStatus = files?.actions?.fetchFile?.status;

  React.useEffect(() => {
    if (!filesContent || !fetchFileContent) {
      return;
    }
    const isPageStale = (page) =>
      !filesContent[fileId]?.[page] ||
      dayjs().diff(filesContent[fileId]?.[page]?.requestedDate, 'seconds') >
        TTL_EXPIRY_SECONDS;

    const pagesToFetch: number[] = [];

    for (
      let i = currPage - PAGE_RANGE;
      i <= Math.min(currPage + PAGE_RANGE, totalPages);
      i++
    ) {
      if (i > 0 && isPageStale(i)) {
        pagesToFetch.push(i);
      }
    }

    if (pagesToFetch.length > 0) {
      fetchFileContent({
        fileId,
        pages: pagesToFetch,
      });
    }
  }, [fetchFileContent, fileId, filesContent, currPage, totalPages]);

  React.useEffect(() => {
    if (currPage !== undefined) {
      setSelectedPage(currPage);
    }
  }, [currPage, setSelectedPage]);

  if (!filesContent?.[fileId]?.[currPage] && fetchFileStatus === 'pending') {
    return <Spin className={styles.spinner} />;
  }

  return !filesContent?.[fileId]?.[currPage] &&
    fetchFileStatus === 'rejected' ? (
    <Typography.Text>
      {messages['claim_overview.files.view.error']}
    </Typography.Text>
  ) : (
    <ClaimFileViewer
      onDownload={downloadFile}
      fileUrl={filesContent?.[fileId]?.[currPage]?.['url']}
      fileId={fileId}
      currPage={currPage}
      totalPages={totalPages}
      onChangePage={(offset) => {
        setSelectedPage(currPage + offset);
      }}
    />
  );
};

export default ClaimFileViewerContainer;
