import { call } from 'typed-redux-saga';
import { localizationClient } from '@owl-frontend/localization';
import { asyncActionStateMatchers } from '@owl-frontend/redux';
import localizationSlice, { LocalizationState } from './interface';

function* fetchMessages(action: {
  payload: { locale: LocalizationState['locale'] };
}) {
  const { locale } = action.payload;
  return {
    messages: yield* call(
      localizationClient.translations.platform.fetch,
      locale
    ),
    locale,
  };
}

const slice = localizationSlice
  .addAsyncSagas({
    fetchMessages,
  })
  .addReducers({
    'fetchMessages/fulfilled': (state, action) => {
      const { messages, locale } = action.payload;
      state.messages = messages;
      state.locale = locale;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
