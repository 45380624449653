import React from 'react';
import { useField } from 'react-final-form';
import Input, { InputProps } from '../../components/input/Input';
import ErrorDisplayComponent from '../ErrorDisplayComponent';
import { getFieldId } from '../helpers';
import type { ManagedFieldProps } from '../interface';

type FieldValue = string; // | number;
export interface Props extends InputProps, ManagedFieldProps<FieldValue> {
  name: string & InputProps['name'] & ManagedFieldProps<FieldValue>['name'];
  defaultValue?: ManagedFieldProps<FieldValue>['defaultValue'];
}

const InputManaged: React.FC<Props> = (props) => {
  const { id: idProp, name, defaultValue, validate, ...inputProps } = props;
  const id = React.useMemo(() => idProp || getFieldId(name), [idProp, name]);
  const { input, meta } = useField(name, { defaultValue, validate });
  const { invalid, modified, touched } = meta;
  const error = meta.error || meta.submitError;

  const modifiedOrTouched = modified || touched;
  const fetchErrorMessage = React.useCallback(() => {
    if (!modifiedOrTouched || !invalid || !error) {
      return null;
    }
    return error;
  }, [modifiedOrTouched, invalid, error]);

  return (
    <Input
      id={id}
      onChange={input.onChange}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      error={modifiedOrTouched && invalid && error}
      value={input.value}
      trailer={<ErrorDisplayComponent errorMessage={fetchErrorMessage()} />}
      {...inputProps}
    />
  );
};

export default InputManaged;
