import React from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Input, Layout, Tabs, Typography } from 'antd';
import { useEffectWithPrev } from '@owl-frontend/components';
import { UnionExpand } from '@owl-lib/type-util';
import { ClaimProvider } from '../../api/claims/interface';
import { ClaimFile } from '../../api/files/interface';
import { SearchHistory } from '../../api/search/interface';
import { Locale } from '../../context/AppProvider';
import { StoreState } from '../../data/store';
import { RouteParams } from '../../helpers/route-types';
import { ClaimStatusComponent } from '../ClaimsContainer/ClaimsListComponent/ClaimsListComponent';
import { UserContextMenuComponent } from '../MainContainer/MainContainer';
import { generatePath, RouteDefs } from '../Routing/Routing';
import useClaimOverviewHook from './useClaimOverviewHook';
import AskOwlContainer from './AskOwlComponent/AskOwlContainer';
import useAskOwlHook from './AskOwlComponent/useAskOwlHook';
import ClaimActivitiesContainer from './ClaimActivitiesContainer/ClaimActivitiesContainer';
import styles from './ClaimOverviewContainer.module.scss';
import ClaimReportContainer from './ClaimReportContainer/ClaimReportContainer';
import useClaimDatapointsHook from './ClaimReportContainer/useClaimDatapointsHook';
import FilesListComponent from './FilesListComponent/FilesListComponent';
import useFilesHook from './FilesListComponent/useFilesHook';

export type Params = RouteParams<RouteDefs.ClaimOverview>;

const TabsOverflowContainer: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <Layout className={styles.tabsOverflowContainer}>{children}</Layout>;
};

export const ClaimDetailsContext = React.createContext<{
  claimMetadata?: Claim;
  files?: {
    currentFiles?: Record<string, ClaimFile>;
    filesContent?: {
      [fileId: string]: {
        [pageNumber: string]: {
          url: string;
          requestedDate: string;
        };
      };
    };
    filesHookHandler: {
      fetchFileContents(data: { fileId: string; pages: number[] });
      downloadFile(fileId: string);
    };
    actions: StoreState['files']['actions'];
  };
  askOwl?: {
    searchHistory?: SearchHistory[];
    localSearchPrompt?: string;
    askOwlHookHandler: {
      search(query: string);
    };
  };
  providers?: {
    data: {
      [provider_id: string]: ClaimProvider;
    };
    fetchClaimProvidersAction: StoreState['claim-overview']['actions']['fetchClaimProviders'];
  };
}>({});

const ClaimOverview: React.FC = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { dossierId } = useParams() as UnionExpand<Params>;

  const { messages, broadcastMessage } = React.useContext(Locale);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [{ claimMetadata, actions }, claimOverviewHandler] =
    useClaimOverviewHook(dossierId);
  const [{ files, filesContent, actions: fileActions }, filesHandler] =
    useFilesHook();
  const [{ searchHistory, localSearchPrompt }, askOwlHandler] =
    useAskOwlHook(dossierId);
  const [
    { claimDatapoints, actions: claimDatapointsActions },
    claimDatapointsHandler,
  ] = useClaimDatapointsHook(dossierId);

  const activeTabKey = React.useMemo(() => {
    return hash || '#report';
  }, [hash]);

  const tabItems = React.useMemo(() => {
    return [
      {
        key: '#report',
        label: messages['claim_overview.report.title'],
        children: (
          <TabsOverflowContainer>
            <ClaimReportContainer />
          </TabsOverflowContainer>
        ),
      },
      // {
      //   key: 'templates',
      //   label: messages['claim_overview.templates.title'],
      //   children: <>templates</>,
      // },
      {
        key: '#files',
        label: messages['claim_overview.files.title'],
        children: (
          <TabsOverflowContainer>
            <FilesListComponent />
          </TabsOverflowContainer>
        ),
      },
      {
        key: '#notes',
        label: messages['claim_overview.activities.title'],
        children: (
          <TabsOverflowContainer>
            <ClaimActivitiesContainer />
          </TabsOverflowContainer>
        ),
      },
      {
        key: '#askOwl',
        label: messages['claim_overview.ask_owl.title'],
        children: (
          <TabsOverflowContainer>
            <AskOwlContainer />
          </TabsOverflowContainer>
        ),
      },
    ];
  }, [messages]);

  React.useEffect(() => {
    claimOverviewHandler.fetchClaim();
  }, [claimOverviewHandler]);

  React.useEffect(() => {
    filesHandler.fetchFiles(dossierId);
  }, [dossierId, filesHandler]);

  React.useEffect(() => {
    askOwlHandler.fetchSearchHistory(dossierId);
  }, [dossierId, askOwlHandler]);

  React.useEffect(() => {
    claimDatapointsHandler.fetchProviders();
  }, [claimDatapointsHandler]);

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (actions.fetchClaim.status === 'rejected') {
        broadcastMessage({
          id: 'claim_overview.error.failed_fetch',
          type: 'error',
        });
        navigate(generatePath(RouteDefs.Claims));
      }
    },
    [actions.fetchClaim.status, broadcastMessage, messages, navigate]
  );

  return (
    <ClaimDetailsContext.Provider
      value={{
        claimMetadata,
        files: {
          currentFiles: files[dossierId],
          filesContent,
          filesHookHandler: filesHandler,
          actions: fileActions,
        },
        askOwl: {
          searchHistory,
          localSearchPrompt,
          askOwlHookHandler: askOwlHandler,
        },
        providers: {
          data: claimDatapoints?.['providers']?.datapoints.reduce(
            (acc, curr) => {
              return {
                ...acc,
                [curr.content.provider_id]: curr.content,
              };
            },
            {}
          ),
          fetchClaimProvidersAction: claimDatapointsActions.fetchClaimProviders,
        },
      }}
    >
      <Layout className={styles.layout}>
        <Layout.Header className={styles.header}>
          <Button
            type="text"
            icon={<ArrowLeftOutlined />}
            onClick={() => {
              navigate(RouteDefs.Claims);
            }}
          >
            {messages['claims.title']}
          </Button>
          <div className={styles.titleContainer}>
            <Typography.Title level={3}>
              {claimMetadata?.dossier_name}
            </Typography.Title>
            <Typography.Title level={4} type="secondary">
              {claimMetadata?.client_dossier_id}
            </Typography.Title>
            {claimMetadata?.data?.claim_settlement_status_date && (
              <ClaimStatusComponent
                claimStatus={claimMetadata?.data.claim_settlement_status_date}
              />
            )}
          </div>
          <UserContextMenuComponent />
        </Layout.Header>
        <Layout.Content>
          <Tabs
            className={styles.tabs}
            items={tabItems}
            tabBarExtraContent={
              <Input.Search
                value={searchValue}
                placeholder={
                  messages['claim_overview.ask_owl.toolbar_placeholder']
                }
                onSearch={(value) => {
                  askOwlHandler.setLocalSearchPrompt(value);
                  navigate(
                    `${generatePath(RouteDefs.ClaimOverview, {
                      dossierId,
                    })}#askOwl`
                  );
                  setSearchValue('');
                }}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            }
            defaultActiveKey={hash}
            activeKey={activeTabKey}
            onChange={(key) => {
              navigate(
                `${generatePath(RouteDefs.ClaimOverview, {
                  dossierId,
                })}${key}`
              );
            }}
          />
        </Layout.Content>
      </Layout>
    </ClaimDetailsContext.Provider>
  );
};

export default ClaimOverview;
