import React from 'react';
import { useLocation } from 'react-router-dom';
import { Locale } from '../../context/AppProvider';

export enum ErrorType {
  MissingTenantId = 'missing_tenant_id',
}

const OwlVisionContainer: React.FC = () => {
  const { state } = useLocation();
  const { messages } = React.useContext(Locale);

  const errorMessage = React.useMemo(() => {
    if (!state?.errors) {
      return messages['platform.errors.default'];
    }

    return messages[state.errors];
  }, [state?.errors, messages]);

  return <div>{errorMessage}</div>;
};

export default OwlVisionContainer;
