// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-AdminPanelContainer-__menu--I1idV {\n  height: 100%;\n}\n\n.components-AdminPanelContainer-__sider---0FRC {\n  border-right: 0.5px solid #d9d9d9;\n}\n\n.components-AdminPanelContainer-__table--bkawC {\n  padding: 24px;\n}", "",{"version":3,"sources":["webpack://./src/components/AdminPanelContainer/AdminPanelContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,iCAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".menu {\n  height: 100%;\n}\n\n.sider {\n  border-right: 0.5px solid #d9d9d9;\n}\n\n.table {\n  padding: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "components-AdminPanelContainer-__menu--I1idV",
	"sider": "components-AdminPanelContainer-__sider---0FRC",
	"table": "components-AdminPanelContainer-__table--bkawC"
};
export default ___CSS_LOADER_EXPORT___;
