// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-ClaimOverviewContainer-FilesListComponent-__contentContainer---6ANj {\n  padding: 1rem;\n}\n\n.components-ClaimOverviewContainer-FilesListComponent-__fileContainer--pta3c {\n  margin-bottom: 16px;\n  padding: 16px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  border-color: #d9d9d9;\n}\n\n.components-ClaimOverviewContainer-FilesListComponent-__fileDetailsModal--D1GvC.ant-modal .ant-modal-content {\n  padding: 0;\n  margin-top: -5rem;\n  margin-bottom: -1.5rem;\n}\n\n.components-ClaimOverviewContainer-FilesListComponent-__fileUploadDate--QeGDB {\n  padding-right: 5px;\n}\n\n.components-ClaimOverviewContainer-FilesListComponent-__table--1i9Nq {\n  border: 1px solid #d9d9d9;\n  border-radius: 8px;\n  overflow: hidden;\n  margin-top: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/ClaimOverviewContainer/FilesListComponent/FilesListComponent.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AAGE;EACE,UAAA;EACA,iBAAA;EACA,sBAAA;AAAJ;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;AADF","sourcesContent":[".contentContainer {\n  padding: 1rem;\n}\n\n.fileContainer {\n  margin-bottom: 16px;\n  padding: 16px;\n  background-color: #ffffff;\n  border-radius: 8px;\n  border-color: #d9d9d9;\n}\n\n.fileDetailsModal {\n  &:global(.ant-modal .ant-modal-content) {\n    padding: 0;\n    margin-top: -5rem;\n    margin-bottom: -1.5rem;\n  }\n}\n\n.fileUploadDate {\n  padding-right: 5px;\n}\n\n.table {\n  border: 1px solid #d9d9d9;\n  border-radius: 8px;\n  overflow: hidden;\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentContainer": "components-ClaimOverviewContainer-FilesListComponent-__contentContainer---6ANj",
	"fileContainer": "components-ClaimOverviewContainer-FilesListComponent-__fileContainer--pta3c",
	"fileDetailsModal": "components-ClaimOverviewContainer-FilesListComponent-__fileDetailsModal--D1GvC",
	"fileUploadDate": "components-ClaimOverviewContainer-FilesListComponent-__fileUploadDate--QeGDB",
	"table": "components-ClaimOverviewContainer-FilesListComponent-__table--1i9Nq"
};
export default ___CSS_LOADER_EXPORT___;
