import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { Layout, Modal, Table, Typography } from 'antd';
import { PRETTY_DATE_FORMAT } from '@owl-frontend/components';
import { ClaimFile, Document } from '../../../api/files/interface';
import { Locale } from '../../../context/AppProvider';
import ClaimFileDetailsContainer from '../ClaimFilesContainer/ClaimFileDetailsContainer';
import { ClaimDetailsContext } from '../ClaimOverviewContainer';
import { formatString } from '../Utils';
import styles from './FilesListComponent.module.scss';

const FilesListComponent: React.FC = () => {
  const intl = useIntl();
  const { messages } = React.useContext(Locale);
  const { files } = React.useContext(ClaimDetailsContext);
  const currentFiles = files?.currentFiles;
  const { Text } = Typography;
  const [selectedDocument, setSelectedDocument] =
    React.useState<Document | null>(null);
  const [detailsModal, setDetailsModal] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState<ClaimFile | null>(null);

  const columns = React.useMemo(
    () => [
      {
        title: messages['claim_overview.files.file_list.headers.document_type'],
        dataIndex: 'document_type',
        key: 'document_type',
        render: (_, data) => (
          <Typography.Link
            onClick={() => {
              setDetailsModal(true);
              const newlySelectedFile = currentFiles?.[data.file_id];
              setSelectedFile(newlySelectedFile ? newlySelectedFile : null);
              setSelectedDocument({
                file_id: data.file_id,
                document_id: data.document_id,
                document_type: data.document_type,
                start_page: data.start_page,
                end_page: data.end_page,
                description: data.description,
                extracted_date: data.extracted_date,
              });
            }}
          >
            {data.document_type}
          </Typography.Link>
        ),
      },
      {
        title: messages['claim_overview.files.file_list.headers.summary'],
        dataIndex: 'summary',
        key: 'summary',
      },
      {
        title: messages['claim_overview.files.file_list.headers.pages'],
        dataIndex: 'pages',
        key: 'pages',
      },
      {
        title: messages['claim_overview.files.file_list.headers.date'],
        dataIndex: 'date',
        key: 'date',
        render: (value: string) => {
          return dayjs(value).format(PRETTY_DATE_FORMAT);
        },
        sorter: (a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf(),
      },
    ],
    [currentFiles, messages]
  );

  return (
    <Layout className={styles.contentContainer}>
      {currentFiles &&
        Object.values(currentFiles)?.map((file) => {
          return (
            <div key={file.file_id} className={styles.fileContainer}>
              <Text className={styles.fileUploadDate}>
                {intl.formatMessage(
                  {
                    id: 'claim_overview.files.file_list.row_content.uploaded_at',
                  },
                  { date: dayjs(file.updated_at).format(PRETTY_DATE_FORMAT) }
                )}
              </Text>
              <Text type={'secondary'}>{file.file_name}</Text>
              <Table
                className={styles.table}
                dataSource={file.documents?.map((document) => ({
                  key: document.document_id,
                  document_type: formatString(document.document_type),
                  start_page: document.start_page,
                  summary: document.description,
                  document_id: document.document_id,
                  file_id: file.file_id,
                  pages:
                    document.start_page === document.end_page
                      ? intl.formatMessage(
                          {
                            id: 'claim_overview.files.file_list.row_content.page',
                          },
                          { page: document.start_page }
                        )
                      : intl.formatMessage(
                          {
                            id: 'claim_overview.files.file_list.row_content.pages',
                          },
                          { start: document.start_page, end: document.end_page }
                        ),
                  date: document.extracted_date,
                }))}
                columns={columns}
              />
            </div>
          );
        })}
      <Modal
        open={detailsModal}
        footer={false}
        width={'100%'}
        onCancel={() => setDetailsModal(false)}
        className={styles.fileDetailsModal}
        destroyOnClose={true}
      >
        <ClaimFileDetailsContainer
          documents={selectedFile ? selectedFile.documents : []}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          totalPages={selectedFile ? selectedFile.total_pages : 0}
        />
      </Modal>
    </Layout>
  );
};

export default FilesListComponent;
