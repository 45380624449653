import { createLogger } from '@owl-lib/logger';
import { deserialize, fetchWrapper } from '../fetch';
import type { Activity } from './interface';
const logger = createLogger(__filename);

const activitiesApiClient = {
  fetchActivities: async (dossierId: string): Promise<Activity[]> => {
    const url = new URL(
      `/app/v1/activities/dossier/${dossierId}`,
      API_BASE_URL
    );
    logger.trace('activitiesApiClient.activities()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
};

export default activitiesApiClient;
