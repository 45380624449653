import { createLogger } from '@owl-lib/logger';
import { deserialize, fetchWrapper } from '../fetch';
import type { User } from '../interface';
const logger = createLogger(__filename);

const usersApiClient = {
  users: async (): Promise<User[]> => {
    const url = new URL('/app/v1/users', API_BASE_URL);
    logger.trace('usersApiClient.users()');
    return deserialize(
      await fetchWrapper(url.href, { method: 'GET', credentials: 'include' })
    );
  },
  create: async (
    userData: Pick<User, 'email' | 'client_user_id' | 'roles'>
  ): Promise<number> => {
    const url = new URL('/app/v1/users', API_BASE_URL);
    logger.trace('usersApiClient.create()');

    return deserialize(
      await fetchWrapper(url.href, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      })
    );
  },
  delete: async (email: string): Promise<number> => {
    const url = new URL('/app/v1/users/', API_BASE_URL);
    url.searchParams.set('user_email_to_delete', email);
    logger.trace('usersApiClient.delete()');

    return deserialize(
      await fetchWrapper(url.href, { method: 'DELETE', credentials: 'include' })
    );
  },
};

export default usersApiClient;
