import { ASYNC_TIMEOUT_MS } from '@owl-lib/util';

const toJson = async (res: Response) => {
  const text = await res.text();
  return text.length ? JSON.parse(text) : {};
};

export function deserialize(res: Response): Promise<any> {
  return res.ok
    ? toJson(res)
    : toJson(res).then(async (data) => {
        throw deserializeError(res, data);
      });
}

export class HTTPError extends Error {
  public response: Response;
  public data: any;

  constructor(res: Response, data: any) {
    super(
      typeof data === 'string'
        ? data
        : 'message' in data
        ? data.message
        : 'unknown error'
    );

    this.response = res;
    this.data = data;
  }
}

export function deserializeError(res: Response, data: any): HTTPError {
  return new HTTPError(res, data);
}

export const fetchWrapper = async (
  href: string,
  options: RequestInit,
  timeoutLimit = ASYNC_TIMEOUT_MS
): Promise<Response> => {
  const controller = new AbortController();
  let timeout: ReturnType<typeof setTimeout> | null = null;

  if (timeoutLimit > 0) {
    timeout = setTimeout(() => controller.abort(), timeoutLimit);
  }

  const response = await fetch(href, {
    ...options,
    signal: controller.signal,
  });

  if (timeout) {
    clearTimeout(timeout);
  }

  return response;
};
