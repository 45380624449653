// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-ClaimOverviewContainer-ClaimReportContainer-__content--TzvXi {\n  height: 100%;\n}\n\n.components-ClaimOverviewContainer-ClaimReportContainer-__contentContainer--9CT5m {\n  padding: 1rem;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n}\n\n.components-ClaimOverviewContainer-ClaimReportContainer-__contentPadding--XGxHK {\n  position: relative;\n  height: 800px;\n}\n.components-ClaimOverviewContainer-ClaimReportContainer-__contentPadding--XGxHK::after {\n  position: absolute;\n  margin-left: 0;\n  margin-right: 0;\n  bottom: 1rem;\n  content: \"end of content\";\n}", "",{"version":3,"sources":["webpack://./src/components/ClaimOverviewContainer/ClaimReportContainer/ClaimReportContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AACF;;AAGA;EACE,kBAAA;EACA,aAAA;AAAF;AACE;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;AACJ","sourcesContent":[".content {\n  height: 100%;\n}\n\n.contentContainer {\n  padding: 1rem;\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n}\n\n// guarantees all items in the menu can be highlighted\n.contentPadding {\n  position: relative;\n  height: 800px;\n  &::after {\n    position: absolute;\n    margin-left: 0;\n    margin-right: 0;\n    bottom: 1rem;\n    content: 'end of content';\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "components-ClaimOverviewContainer-ClaimReportContainer-__content--TzvXi",
	"contentContainer": "components-ClaimOverviewContainer-ClaimReportContainer-__contentContainer--9CT5m",
	"contentPadding": "components-ClaimOverviewContainer-ClaimReportContainer-__contentPadding--XGxHK"
};
export default ___CSS_LOADER_EXPORT___;
