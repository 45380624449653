import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '../../../api/interface';
import { StoreState } from '../../../data/store';
import { actions as usersSliceActions } from '../../../data/users/logic';

export interface UsersHookState {
  users: User[];
  actions: StoreState['users']['actions'];
}

interface UsersHookHandler {
  fetchUsers(): void;
  createUser(user: User): void;
  deleteUser(email: string): void;
}

const useUsersHook = (): [UsersHookState, UsersHookHandler] => {
  const dispatch = useDispatch();
  const { users = [], actions } = useSelector((s: StoreState) => s['users']);

  const handler = React.useMemo(
    () => ({
      fetchUsers: () => {
        dispatch(usersSliceActions.users());
      },
      createUser: (user: Pick<User, 'email' | 'client_user_id' | 'roles'>) => {
        dispatch(usersSliceActions.createUser(user));
      },
      deleteUser: (email: string) => {
        dispatch(usersSliceActions.deleteUser(email));
      },
    }),
    []
  );

  return [{ users, actions }, handler];
};

export default useUsersHook;
