import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';
import styles from './AdminPanelContainer.module.scss';

const { Sider } = Layout;

const items: MenuProps['items'] = ['User Management'].map((label, index) => ({
  key: `${index}`,
  label,
}));

const AdminPanelContainer: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState('0');

  return (
    <Layout>
      <Sider className={styles.sider}>
        <Menu
          selectedKeys={[selectedKey]}
          onClick={({ key }) => setSelectedKey(key)}
          className={styles.menu}
          items={items}
        />
      </Sider>
      <Layout className={styles.table}>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default AdminPanelContainer;
