import dayjs from 'dayjs';
import { call, SagaGenerator } from 'typed-redux-saga';
import {
  type AsyncActionState,
  asyncActionStateMatchers,
  setupSlice,
} from '@owl-frontend/redux';
import claimsClient from '../../../api/claims/client';
import type { Claim } from '../../../api/claims/interface';

function* fetchClaims(action: {
  payload: {
    filters: {
      name_or_id?: string;
      intake_date?: [dayjs.Dayjs, dayjs.Dayjs];
      owners?: string[];
      status?: string;
    };
    sort: {
      key: string[];
      order: 'ascend' | 'descend';
    } | null;
  };
}): SagaGenerator<Claim[]> {
  const {
    filters: { name_or_id, intake_date, owners, status },
    sort,
  } = action.payload;

  const mappedFilters: Array<
    | {
        filter_name: 'name_or_id';
        data: { query: string };
      }
    | {
        filter_name: 'owner';
        data: {
          owners: string[];
        };
      }
    | {
        column: string;
        json_path: string[];
        operator: string;
        value: string;
      }
    | {
        filter_name?: never;
        column?: never;
      }
  > = [];

  if (name_or_id) {
    mappedFilters.push({
      filter_name: 'name_or_id',
      data: {
        query: name_or_id,
      },
    });
  }

  if (intake_date) {
    const [startDate, endDate] = intake_date;

    mappedFilters.push({
      column: 'data',
      json_path: ['cid_date'],
      operator: '>=',
      value: dayjs(startDate).format('YYYY-MM-DD'),
    });

    mappedFilters.push({
      column: 'data',
      json_path: ['cid_date'],
      operator: '<=',
      value: dayjs(endDate).format('YYYY-MM-DD'),
    });
  }

  if (owners) {
    mappedFilters.push({
      filter_name: 'owner',
      data: {
        owners,
      },
    });
  }

  if (status && status !== 'all') {
    mappedFilters.push({
      column: 'data',
      json_path: ['claim_settlement_status_date'],
      operator: '=',
      value: status,
    });
  }

  return yield* call(claimsClient.fetch, {
    filters: JSON.stringify(mappedFilters),
    sort: sort
      ? {
          sort_by: sort.key,
          is_descending: sort.order === 'descend',
        }
      : undefined,
  });
}

export interface ClaimsState {
  claims: Claim[];
  actions: {
    fetchClaims: AsyncActionState;
  };
}

const initialState: ClaimsState = {
  claims: [],
  actions: {
    fetchClaims: { status: 'uninitialized' },
  },
};

const slice = setupSlice('claims', initialState)
  .addAsyncSagas({
    fetchClaims,
  })
  .addReducers({
    'fetchClaims/fulfilled': (state, action) => {
      state.claims = action.payload;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
