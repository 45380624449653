import React from 'react';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Card, Descriptions, Space } from 'antd';
import { PRETTY_DATE_FORMAT } from '@owl-frontend/components';
import { UnionExpand } from '@owl-lib/type-util';
import { Claim } from '../../../../api/claims/interface';
import { Locale } from '../../../../context/AppProvider';
import { ClaimDetailsContext, Params } from '../../ClaimOverviewContainer';
import ClaimSummariesComponent from '../../ClaimSummaryComponent/ClaimSummariesComponent';
import useClaimSummaryHook from '../useClaimSummaryHook';

export const ClaimReportMetadata: React.FC<{
  data: Claim['data'];
  displayFields: { key: string; dataType?: 'date' }[];
}> = ({ data, displayFields }) => {
  const { messages } = React.useContext(Locale);

  const renderValue = React.useCallback(
    (valueMeta: { key: string; dataType?: 'date' }) => {
      const { key, dataType } = valueMeta;
      const value = data[key];

      if (!value) {
        return '-';
      }

      if (dataType === 'date' && dayjs(value).isValid()) {
        return dayjs(value).format(PRETTY_DATE_FORMAT);
      }

      return value;
    },
    [data]
  );

  return (
    <Descriptions layout="vertical" size="small" colon={false}>
      {displayFields.map((d) => (
        <Descriptions.Item
          key={d.key}
          label={messages[`claim_overview.report.metadata.${d.key}`]}
        >
          {renderValue({ key: d.key, dataType: d.dataType })}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

const ClaimReportOverviewContainer: React.FC = () => {
  const { dossierId } = useParams() as UnionExpand<Params>;
  const { messages } = React.useContext(Locale);
  const { claimMetadata } = React.useContext(ClaimDetailsContext);
  const [{ claimSummary }, useClaimSummaryHookHandler] =
    useClaimSummaryHook(dossierId);

  React.useEffect(() => {
    useClaimSummaryHookHandler.fetchClaimSummary();
  }, [useClaimSummaryHookHandler]);

  if (!claimMetadata) {
    return null;
  }

  return (
    <Space direction="vertical">
      <Card
        title={messages['claim_overview.report.menu.overview.summary']}
        type="inner"
      >
        <ClaimSummariesComponent claimSummaries={claimSummary} />
      </Card>
      <Card
        title={messages['claim_overview.report.menu.overview.claimant']}
        type="inner"
      >
        <ClaimReportMetadata
          data={claimMetadata.data}
          displayFields={[
            { key: 'first_nm' },
            { key: 'mid_nm' },
            { key: 'last_nm' },
            { key: 'sufix_nm' },
            { key: 'birth_date', dataType: 'date' },
            { key: 'gender_code' },
            { key: 'claim_number' },
            { key: 'disability_begin_date', dataType: 'date' },
            { key: 'job_title_code' },
            { key: 'activity_name' },
            { key: 'full_time_rtw_date', dataType: 'date' },
            { key: 'part_time_rtw_date', dataType: 'date' },
          ]}
        />
      </Card>
      <Card
        title={
          messages[
            'claim_overview.report.menu.overview.claim_status_and_history'
          ]
        }
        type="inner"
      >
        <ClaimReportMetadata
          data={claimMetadata.data}
          displayFields={[
            { key: 'claim_event_date', dataType: 'date' },
            { key: 'claim_received_date', dataType: 'date' },
            { key: 'cid_date', dataType: 'date' },
            { key: 'claim_settlement_date', dataType: 'date' },
            { key: 'claim_settlement_status_date' },
          ]}
        />
      </Card>
      <Card
        title={messages['claim_overview.report.menu.overview.medical']}
        type="inner"
      >
        <ClaimReportMetadata
          data={claimMetadata.data}
          displayFields={[
            { key: 'diagnosis_code' },
            { key: 'diagnosis_type_identifier' },
            { key: 'secondary_diagnosis_code' },
            { key: 'secondary_type_identifier' },
          ]}
        />
      </Card>
    </Space>
  );
};

export default ClaimReportOverviewContainer;
