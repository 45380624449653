import React from 'react';
import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import clsx from 'clsx';
import { Image, Typography, Space } from 'antd';
import styles from './ClaimFileViewer.module.scss';

const PAGE_OFFSET = 1;
const ZOOM_SCALE = 0.25;
const MIN_SCALE = -1;
const MAX_SCALE = 2;
interface Props {
  fileId: string;
  fileUrl?: string;
  currPage: number;
  totalPages: number;
  onDownload?(fileId: string): void;
  onChangePage(page: number): void;
}

const ClaimFileViewer: React.FC<Props> = ({
  fileId,
  fileUrl,
  currPage,
  totalPages,
  onDownload,
  onChangePage,
}) => {
  const [scale, setScale] = React.useState(1);

  const changeScale = React.useCallback((offset: number) => {
    setScale((prevScale) => prevScale + offset);
  }, []);

  const changePage = React.useCallback(
    (offset: number) => {
      setScale(1);
      onChangePage(offset);
    },
    [onChangePage]
  );

  if (!fileUrl) {
    return;
  }

  return (
    <div className={styles.fileViewerContainer}>
      <div className={styles.imageContainer}>
        <Image
          preview={false}
          src={fileUrl}
          alt={`Page ${currPage}`}
          style={{
            width: '100%',
            height: '100%',
            transform: `scale(${scale})`,
            transformOrigin: 'top left',
          }}
          fallback={'Error loading doc...'}
        />
      </div>
      <div className={styles.toolBar}>
        <Space>
          <LeftOutlined
            onClick={() => {
              changePage(-PAGE_OFFSET);
            }}
            className={clsx([
              currPage <= 1 ? styles.inactiveTooltip : styles.activeTooltip,
            ])}
          />
          <Typography.Text style={{ color: 'white' }}>
            {currPage} of {totalPages}
          </Typography.Text>
          <RightOutlined
            onClick={() => {
              changePage(PAGE_OFFSET);
            }}
            className={clsx([
              currPage >= totalPages
                ? styles.inactiveTooltip
                : styles.activeTooltip,
            ])}
          />
        </Space>
        <Space>
          <ZoomOutOutlined
            onClick={() => {
              changeScale(-ZOOM_SCALE);
            }}
            className={clsx([
              scale <= MIN_SCALE
                ? styles.inactiveTooltip
                : styles.activeTooltip,
            ])}
          />
          <ZoomInOutlined
            onClick={() => {
              changeScale(ZOOM_SCALE);
            }}
            className={clsx([
              scale >= MAX_SCALE
                ? styles.inactiveTooltip
                : styles.activeTooltip,
            ])}
          />
        </Space>
        <Space>
          <DownloadOutlined
            onClick={() => {
              onDownload?.(fileId);
            }}
          />
        </Space>
      </div>
    </div>
  );
};

export default ClaimFileViewer;
