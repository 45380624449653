import React from 'react';
import { Button, GetProps, Input, Select } from 'antd';
import { Locale } from '../../../context/AppProvider';
import styles from './ClaimActivitiesContainer.module.scss';

const { Search } = Input;

interface ActivityFilterProps {
  setSearchValue(value: string): void;
  setFilteredType(type: string[]): void;
  setSortDesc(type: boolean): void;
  sortDesc: boolean;
  activityTypes: string[];
}

const ActivityFilters: React.FC<ActivityFilterProps> = ({
  setSearchValue,
  setFilteredType,
  setSortDesc,
  sortDesc,
  activityTypes,
}) => {
  const { messages } = React.useContext(Locale);

  type SearchProps = GetProps<typeof Input.Search>;
  const onSearch: SearchProps['onSearch'] = React.useCallback(
    (value: string) => {
      setSearchValue(value);
    },
    [setSearchValue]
  );
  return (
    <div className={styles.filtersContainer}>
      <div className={styles.searchItem}>
        <label className={styles.filterLabel}>
          {messages['claim_overview.activities.filter_options.search']}
        </label>
        <Search
          placeholder={
            messages['claim_overview.activities.filter_options.search_bar']
          }
          onSearch={onSearch}
        />
      </div>
      <div className={styles.filterItem}>
        <label className={styles.filterLabel}>
          {messages['claim_overview.activities.filter_options.type']}
        </label>
        <Select
          allowClear
          maxTagCount="responsive"
          mode="multiple"
          onChange={(value) => setFilteredType(value)}
          options={activityTypes.map((activityType) => ({
            value: activityType,
            label: activityType,
          }))}
        />
      </div>
      <div className={styles.buttonItem}>
        <Button
          className={styles.sortButton}
          type="link"
          onClick={() => {
            setSortDesc(!sortDesc);
          }}
        >
          {sortDesc
            ? messages[
                'claim_overview.activities.filter_options.sort_old_to_new'
              ]
            : messages[
                'claim_overview.activities.filter_options.sort_new_to_old'
              ]}
        </Button>
      </div>
    </div>
  );
};

export default ActivityFilters;
