import React from 'react';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import { useIntl } from 'react-intl';
import { Layout, Table, message } from 'antd';
import {
  PRETTY_DATE_FORMAT,
  useEffectWithPrev,
} from '@owl-frontend/components';
import { User } from '../../../api/interface';
import { Locale } from '../../../context/AppProvider';
import useUsersHook from './useUsersHook';
import DeleteUsersComponent from './DeleteUsersComponent';
import NewUserModal from './NewUserModal';
import styles from './UsersContainer.module.scss';
import UsersFilterComponent from './UsersFilterComponent';

const UsersContainer: React.FC = () => {
  const { messages } = React.useContext(Locale);
  const intl = useIntl();
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [filteredRole, setFilteredRole] = React.useState<string>('');
  const [searchValue, setSearchValue] = React.useState('');
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [
    {
      users,
      actions: { createUser, deleteUser },
    },
    handler,
  ] = useUsersHook();

  const fuse = React.useMemo(() => {
    return new Fuse(users, {
      isCaseSensitive: false,
      useExtendedSearch: true,
      keys: ['email'],
    });
  }, [users]);

  const existingEmails = React.useMemo(
    () => users.map((u) => u.email),
    [users]
  );

  const filteredUsers = React.useMemo(() => {
    let usersToFilter = users;
    if (searchValue && searchValue.trim() !== '') {
      usersToFilter = fuse
        .search(`'${searchValue.trim()}`)
        .map((result) => result.item as User);
    }
    if (filteredRole) {
      usersToFilter = usersToFilter.filter((user) =>
        user.roles.some(
          (role) => role.toLowerCase() === filteredRole.toLowerCase()
        )
      );
    }

    return usersToFilter;
  }, [users, searchValue, filteredRole, fuse]);

  const dataSource = React.useMemo(
    () =>
      filteredUsers?.map((user) => ({
        key: user.email,
        user: user.email,
        roles: user.roles
          .map((role) => role.charAt(0).toUpperCase() + role.slice(1))
          .join(', '),
        date_created: user.created_at,
        last_updated: user.updated_at,
      })),
    [filteredUsers]
  );

  const columns = React.useMemo(
    () => [
      {
        title: messages['users.list.headers.user'],
        dataIndex: 'user',
        key: 'user',
      },
      {
        title: messages['users.list.headers.roles'],
        dataIndex: 'roles',
        key: 'roles',
      },
      {
        title: messages['users.list.headers.last_updated'],
        dataIndex: 'last_updated',
        key: 'last_updated',
        render: (value: string) => {
          return dayjs(value).format(PRETTY_DATE_FORMAT);
        },
      },
      {
        title: messages['users.list.headers.date_created'],
        dataIndex: 'date_created',
        key: 'date_created',
        render: (value: string) => {
          return dayjs(value).format(PRETTY_DATE_FORMAT);
        },
      },
    ],
    [messages]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }
      messageApi.destroy(); // remove the pending message
      if (createUser.status === 'fulfilled') {
        messageApi.success(
          intl.formatMessage(
            { id: 'users.create.messages.success' },
            { user: createUser.result.email, role: createUser.result.roles[0] }
          )
        );
        setIsModalVisible(false);
        handler.fetchUsers();
      } else {
        messageApi.error(messages['users.create.messages.failure']);
      }
    },
    [createUser.status, createUser, messageApi, handler, messages, intl]
  );

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }
      messageApi.destroy(); // remove the pending message
      if (deleteUser.status === 'fulfilled') {
        messageApi.success(
          selectedRowKeys.length === 1
            ? intl.formatMessage(
                { id: 'users.delete.messages.success' },
                { user: deleteUser.result }
              )
            : intl.formatMessage(
                { id: 'users.delete.messages.successes' },
                { num_users: selectedRowKeys.length }
              )
        );
        handler.fetchUsers();
        setSelectedRowKeys([]);
      } else {
        messageApi.error(messages['users.delete.messages.failure']);
      }
    },
    [
      deleteUser.status,
      deleteUser,
      messageApi,
      handler,
      messages,
      intl,
      selectedRowKeys.length,
    ]
  );

  const onCreate = (user): void => {
    messageApi.loading(
      intl.formatMessage(
        { id: 'users.create.messages.loading' },
        { user: user.email }
      )
    );
    user.roles = [user.roles]; // because the modal is a single select
    handler.createUser(user);
  };

  const onDelete = (): void => {
    messageApi.loading(
      intl.formatMessage(
        { id: 'users.delete.messages.loading' },
        { num_users: selectedRowKeys.length }
      )
    );
    for (const key of selectedRowKeys) {
      handler.deleteUser(key as string);
    }
  };

  const onSelectChange = React.useCallback(
    (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    []
  );

  const rowSelection = React.useMemo(() => {
    return {
      selectedRowKeys,
      onChange: onSelectChange,
    };
  }, [selectedRowKeys, onSelectChange]);

  React.useEffect(() => {
    handler.fetchUsers();
  }, [handler]);

  return (
    <Layout>
      {selectedRowKeys.length === 0 ? (
        <UsersFilterComponent
          setFilteredRole={setFilteredRole}
          setIsModalVisible={setIsModalVisible}
          setSearchValue={setSearchValue}
        />
      ) : (
        <DeleteUsersComponent
          deleteUsers={onDelete}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      )}
      <Table
        dataSource={dataSource}
        columns={columns}
        className={styles.table}
        rowSelection={rowSelection}
      />
      {contextHolder}

      <NewUserModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        createUser={onCreate}
        existingEmails={existingEmails}
      />
    </Layout>
  );
};

export default UsersContainer;
