import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClaimFile } from '../../../api/files/interface';
import { actions as filesSliceActions } from '../../../data/files/logic';
import { StoreState } from '../../../data/store';

export interface FilesHookState {
  files: { [dossier_id: string]: Record<string, ClaimFile> } | never[];
  filesContent?: {
    [fileId: string]: {
      [pageNumber: string]: {
        url: string;
        requestedDate: string;
      };
    };
  };
  actions: StoreState['files']['actions'];
}

interface FilesHookHandler {
  fetchFiles(dossier_id: string): void;
  fetchFileContents(data: { fileId: string; pages: number[] });
  downloadFile(fileId: string);
}

const useFilesHook = (): [FilesHookState, FilesHookHandler] => {
  const dispatch = useDispatch();
  const {
    files = [],
    filesContent,
    actions,
  } = useSelector((s: StoreState) => s['files']);

  const handler = React.useMemo(
    () => ({
      fetchFiles: (dossier_id: string) => {
        dispatch(filesSliceActions.files(dossier_id));
      },
      fetchFileContents: (data: { fileId: string; pages: number[] }) => {
        const { fileId, pages } = data;
        dispatch(filesSliceActions.fetchFile({ fileId, pages }));
      },
      downloadFile: (fileId: string) => {
        dispatch(filesSliceActions.downloadFile(fileId));
      },
    }),
    []
  );

  return [{ files, filesContent, actions }, handler];
};

export default useFilesHook;
