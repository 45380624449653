// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-DatapointsRenderEngine-__expandedRow--7FnQL.components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-DatapointsRenderEngine-__expandedRow--7FnQL > .ant-table-cell {\n  padding-left: 0;\n}\n\n.components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-DatapointsRenderEngine-__expandedRowDetails--fUvaf {\n  width: 100%;\n  overflow: auto;\n  padding-left: 64px;\n}\n\n.components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-DatapointsRenderEngine-__descriptionsView--GI8A8.components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-DatapointsRenderEngine-__descriptionsView--GI8A8 .ant-descriptions-item-label {\n  vertical-align: baseline;\n}", "",{"version":3,"sources":["webpack://./src/components/ClaimOverviewContainer/ClaimReportContainer/ClaimReportOverviewDataContainer/DatapointsRenderEngine/DatapointsRenderEngineComponent.module.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ;;AAIA;EACE,WAAA;EACA,cAAA;EACA,kBAAA;AADF;;AAMI;EACE,wBAAA;AAHN","sourcesContent":[".expandedRow {\n  &#{&} > :global(.ant-table-cell) {\n    padding-left: 0;\n  }\n}\n\n.expandedRowDetails {\n  width: 100%;\n  overflow: auto;\n  padding-left: 64px;\n}\n\n.descriptionsView {\n  &#{&} {\n    :global(.ant-descriptions-item-label) {\n      vertical-align: baseline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"expandedRow": "components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-DatapointsRenderEngine-__expandedRow--7FnQL",
	"expandedRowDetails": "components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-DatapointsRenderEngine-__expandedRowDetails--fUvaf",
	"descriptionsView": "components-ClaimOverviewContainer-ClaimReportContainer-ClaimReportOverviewDataContainer-DatapointsRenderEngine-__descriptionsView--GI8A8"
};
export default ___CSS_LOADER_EXPORT___;
