import { call } from 'typed-redux-saga';
import {
  AsyncActionState,
  asyncActionStateMatchers,
  setupSlice,
  invoke,
  SagaGenerator,
} from '@owl-frontend/redux';
import type { User } from '../../api/interface';
import usersApiClient from '../../api/users/client';

function* createUser(action: {
  payload: Pick<User, 'email' | 'client_user_id' | 'roles'>;
}): SagaGenerator<{ email: string; roles: string[] }> {
  const createdUser = action.payload;

  yield* call(usersApiClient.create, action.payload);
  return createdUser;
}

function* deleteUser(action: { payload: string }) {
  yield* call(usersApiClient.delete, action.payload);
  return action.payload;
}

export interface UsersState {
  users: User[];
  actions: {
    users: AsyncActionState;
    createUser: AsyncActionState;
    deleteUser: AsyncActionState;
  };
}

const initialState: UsersState = {
  users: [],
  actions: {
    users: { status: 'uninitialized' },
    createUser: { status: 'uninitialized' },
    deleteUser: { status: 'uninitialized' },
  },
};

const slice = setupSlice('users', initialState)
  .addAsyncSagas({
    users: invoke(usersApiClient.users),
    createUser,
    deleteUser,
  })
  .addReducers({
    'users/fulfilled': (state, action) => {
      state.users = action.payload;
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
