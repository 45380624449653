import React from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { Card, Col, Space, Row } from 'antd';
import { Form } from '@owl-frontend/components';
import { User } from '../../../api/interface';
import { Locale } from '../../../context/AppProvider';
import { ClaimsHookState } from '../useClaimsHook';

interface Props {
  users: User[];
  filters: ClaimsHookState['filters'];
  onFilterChange(data: { filters: ClaimsHookState['filters'] }): void;
}

const ClaimsFilterForm: Form.MFC<Pick<Props, 'users'>> = ({
  users,
  // RFF
  handleSubmit,
  values,
}) => {
  const { messages } = React.useContext(Locale);

  const ownerOptions = React.useMemo(
    () =>
      users.map((u) => ({
        label: u.email,
        value: u.email,
      })),
    [users]
  );

  React.useEffect(() => {
    handleSubmit(values);
  }, [handleSubmit, values]);

  return (
    <form>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row>
          <Col span={24}>
            <Form.InputManaged
              name="name_or_id"
              label={messages['claims.hub.filters.search.label']}
              placeholder={messages['claims.hub.filters.search.placeholder']}
            />
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.DateRangePickerAntdManaged
              name="intake_date"
              label={messages['claims.hub.filters.intake_date.label']}
            />
          </Col>
          <Col span={8}>
            <Form.MultiSelectAntdManaged
              name="owners"
              label={messages['claims.hub.filters.reviewer.label']}
              placeholder={messages['claims.hub.filters.reviewer.placeholder']}
              options={ownerOptions}
            />
          </Col>
          <Col span={8}>
            <Form.SelectAntdManaged
              name="status"
              label={messages['claims.hub.filters.status.label']}
              options={[
                {
                  label: messages['claims.hub.filters.status.values.all'],
                  value: 'all',
                },
                {
                  label: messages['claims.hub.filters.status.values.open'],
                  value: 'OPEN',
                },
                {
                  label: messages['claims.hub.filters.status.values.closed'],
                  value: 'CLSD',
                },
              ]}
            />
          </Col>
        </Row>
      </Space>
    </form>
  );
};

const ClaimsFilterFormManaged = Form.manage(ClaimsFilterForm);

const ClaimsListFilterComponent: React.FC<Props> = ({
  users,
  filters,
  onFilterChange,
}) => {
  const onSubmit = useDebouncedCallback((values) => {
    onFilterChange({ filters: values });
  }, 500);

  return (
    <Card>
      <ClaimsFilterFormManaged
        users={users}
        initialValues={filters}
        onSubmit={onSubmit}
      />
    </Card>
  );
};

export default ClaimsListFilterComponent;
