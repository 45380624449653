import React from 'react';
import { useParams } from 'react-router-dom';
import { Card, Space } from 'antd';
import { UnionExpand } from '@owl-lib/type-util';
import { Locale } from '../../../../context/AppProvider';
import { Params } from '../../ClaimOverviewContainer';
import useClaimDatapointsHook from '../useClaimDatapointsHook';
import datapointsUiSchema from './schemas.json';
import styles from './ClaimReportOverviewDataContainer.module.scss';
import DatapointEngineComponent from './DatapointsRenderEngine/DatapointsRenderEngineComponent';

export const CATEGORY_DATAPOINTS_MAPPING: { [category: string]: string[] } = {
  // render order depends on order of datapoints listed
  correspondence: ['correspondence'],
  medical_assessment: [
    'office_visit_records',
    'hospital_visit_records',
    'therapy',
    'diagnostic_report',
    'medications',
    'treatment_procedure_records',
  ],
};

interface Props {
  activeCategory: string;
}

const ClaimReportOverviewDataContainer: React.FC<Props> = ({
  activeCategory,
}) => {
  const { dossierId } = useParams() as UnionExpand<Params>;
  const { messages } = React.useContext(Locale);
  const [{ claimDatapoints }, handler] = useClaimDatapointsHook(dossierId);

  const onPage = React.useCallback(
    (data: { datapointType: string; offset: number }) => {
      handler.fetchDatapointsByType({
        datapointType: data.datapointType,
        offset: data.offset,
      });
    },
    [handler]
  );

  React.useEffect(() => {
    for (const datapointType of CATEGORY_DATAPOINTS_MAPPING[activeCategory]) {
      handler.fetchDatapointsByType({
        datapointType,
        offset: 0,
      });
    }
  }, [activeCategory, handler]);

  return (
    <Space className={styles.content} direction="vertical">
      {CATEGORY_DATAPOINTS_MAPPING[activeCategory].map((datapointType) => {
        if (
          !datapointsUiSchema[datapointType] ||
          datapointsUiSchema[datapointType].length === 0
        ) {
          return null;
        }

        return (
          <Card
            key={datapointType}
            className={styles.card}
            title={
              messages[
                `claim_overview.report.menu.${activeCategory}.${datapointType}`
              ]
            }
            type="inner"
          >
            <DatapointEngineComponent
              datapointType={datapointType}
              data={claimDatapoints?.[datapointType]?.datapoints ?? []}
              totalCount={claimDatapoints?.[datapointType]?.total_count}
              tableDef={datapointsUiSchema[datapointType].table}
              detailRenderDef={datapointsUiSchema[datapointType].data}
              onPage={onPage}
            />
          </Card>
        );
      })}
    </Space>
  );
};

export default ClaimReportOverviewDataContainer;
