import React from 'react';
import { Button, Descriptions, Modal, Popover, Typography } from 'antd';
import { Citation, ClaimSummary } from '../../../api/claims/interface';
import { ClaimFile, Document } from '../../../api/files/interface';
import { Locale } from '../../../context/AppProvider';
import ClaimFileDetailsContainer from '../ClaimFilesContainer/ClaimFileDetailsContainer';
import { ClaimDetailsContext } from '../ClaimOverviewContainer';
import styles from './ClaimSummariesComponent.module.scss';

export interface CitationComponentProps {
  citation: Citation;
  citationIndex: number;
}

export const CitationComponent: React.FC<CitationComponentProps> = ({
  citationIndex,
  citation,
}) => {
  const { files } = React.useContext(ClaimDetailsContext);
  const currentFiles = files?.currentFiles;
  const [citationDialog, setCitationDialog] = React.useState<boolean>(false);
  const [selectedFile, setSelectedFile] = React.useState<ClaimFile | null>(
    null
  );
  const [selectedDocument, setSelectedDocument] =
    React.useState<Document | null>(null);

  const onCitationClick = React.useCallback(
    (fileId: string, pageNumber: number) => {
      setCitationDialog(true);
      const file = currentFiles?.[fileId];
      setSelectedFile(file || null);

      if (file) {
        const document = file.documents.find(
          (doc) => pageNumber >= doc.start_page && pageNumber <= doc.end_page
        );
        setSelectedDocument(document || null);
      }
    },
    [currentFiles, setCitationDialog, setSelectedFile, setSelectedDocument]
  );

  return (
    <>
      <Popover
        content={
          <div className={styles.citationDetailsContent}>
            <Descriptions layout="vertical" size="small" column={1}>
              <Descriptions.Item label="File name">
                {citation.file_name}
              </Descriptions.Item>
              <Descriptions.Item label="Pages">
                {citation.pages.join(', ')}
              </Descriptions.Item>
            </Descriptions>
          </div>
        }
      >
        <Button
          className={styles.citationButton}
          onClick={() => {
            onCitationClick(citation.file_id, citation.pages[0]);
          }}
        >
          {citationIndex}
        </Button>
      </Popover>
      <Modal
        open={citationDialog}
        onCancel={() => {
          setCitationDialog(false);
        }}
        width={'100%'}
        footer={false}
        destroyOnClose={true}
        className={styles.fileDetailsModal}
      >
        <ClaimFileDetailsContainer
          documents={selectedFile?.documents || []}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
          totalPages={selectedFile?.total_pages || 0}
        />
      </Modal>
    </>
  );
};

/**
 * MARK: Claim Summary Component
 * =============================
 * Renders a claim summary (sentence)
 */

export interface ClaimSummaryComponentProps {
  claimSummary: ClaimSummary;
  claimSummaryIndex: number;
}

export const ClaimSummaryComponent: React.FC<ClaimSummaryComponentProps> = ({
  claimSummary,
  claimSummaryIndex,
}) => {
  return (
    <div className={styles.claimSummaryComponent}>
      {claimSummary.content}
      <div className={styles.citationContainer}>
        {claimSummary.citations.map((c, index) => {
          const citationIndex =
            claimSummaryIndex + index + (claimSummaryIndex === 0 ? 1 : 2);
          return (
            <CitationComponent
              key={citationIndex}
              citationIndex={citationIndex}
              citation={c}
            />
          );
        })}
      </div>
    </div>
  );
};

/**
 * MARK: Claim Summaries Component
 * ===============================
 * Renders collection of claim summaries (sentences)
 */

export interface ClaimSummariesComponentProps {
  claimSummaries?: ClaimSummary[];
}

const ClaimSummariesComponent: React.FC<ClaimSummariesComponentProps> = ({
  claimSummaries = [],
}) => {
  const { messages } = React.useContext(Locale);
  if (!claimSummaries || claimSummaries.length === 0) {
    return (
      <Typography.Text type="secondary">
        {messages['claim_overview.report.summary.no_data']}
      </Typography.Text>
    );
  }

  return (
    <div className={styles.claimSummariesContainer}>
      {claimSummaries.map((c, index) => (
        <ClaimSummaryComponent
          key={c.dossier_summary_id}
          claimSummary={c}
          claimSummaryIndex={index}
        />
      ))}
    </div>
  );
};

export default ClaimSummariesComponent;
