import React from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ConfigProvider, Space, Typography, message } from 'antd';
import { useEffectWithPrev } from '@owl-frontend/components';
import Spinner from '../assets/app-spinner.svg';
import OwlVisionContainer, {
  ErrorType,
} from '../components/OwlVisionLandingContainer/OwlVisionLandingContainer';
import Routing, { RouteDefs } from '../components/Routing/Routing';
import { actions as localizationSliceActions } from '../data/localization/logic';
import { actions as sessionSliceActions } from '../data/session/logic';
import type { StoreState } from '../data/store';

interface Props extends React.PropsWithChildren {
  Router?: React.ComponentType;
}

export const Locale = React.createContext<{
  locale: 'en-us';
  messages: {
    [k: string]: string;
  };
  broadcastMessage(data: {
    id: string;
    type?: 'info' | 'warning' | 'error' | 'loading' | 'success';
  }): void;
}>({
  locale: 'en-us',
  messages: {},
  broadcastMessage: () => {
    throw new Error('Locale Context broadcastMessage not hooked up!');
  },
});

export const SessionContext = React.createContext<
  Omit<StoreState['session'], 'actions'>
>({});

const getCookieValue = (cookieName: string) => {
  return (
    document.cookie
      .split(';')
      .map((cookie) => cookie.split('=').map((part) => part.trim()))
      .find(([cookieKey]) => cookieKey === cookieName)?.[1] || null
  );
};

const ContextProvider: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [messageApi, messageContextHolder] = message.useMessage();
  const { locale, messages } = useSelector(
    (s: StoreState) => s['localization']
  );
  const {
    current,
    actions: {
      current: currentAction,
      requestToken: requestTokenAction,
      requestLogin: requestLoginAction,
      requestLogout: requestLogoutAction,
    },
  } = useSelector((s: StoreState) => s['session']);

  const fetchLoadingMessage = React.useCallback(
    (data: {
      current: boolean;
      requestLogin: boolean;
      requestToken: boolean;
    }) => {
      if (data.current) {
        return messages['platform.start_up.current'];
      }

      if (data.requestLogin) {
        return messages['platform.start_up.request_login'];
      }

      if (data.requestToken) {
        return messages['platform.start_up.request_token'];
      }

      return messages['platform.start_up.default'];
    },
    [messages]
  );

  React.useEffect(() => {
    dispatch(sessionSliceActions.current());
  }, []);

  React.useEffect(() => {
    dispatch(localizationSliceActions.fetchMessages({ locale }));
  }, [locale]);

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (currentAction.status === 'rejected') {
        const authCode = params.get('code');
        const state = params.get('state');

        if (authCode && state) {
          dispatch(sessionSliceActions.requestToken({ authCode, state }));
          return;
        }

        const tenant = getCookieValue('tenant') ?? params.get('tenant');
        if (tenant) {
          dispatch(
            sessionSliceActions.requestLogin({
              tenantId: tenant,
            })
          );
          return;
        }

        navigate(RouteDefs.Broken, {
          state: {
            error: ErrorType.MissingTenantId,
          },
        });
      }
    },
    [currentAction.status, navigate, params]
  );

  if (
    currentAction.status === 'pending' ||
    requestLoginAction.status === 'pending' ||
    requestTokenAction.status === 'pending'
  ) {
    return (
      <div className="app-spinner">
        <Space
          direction="vertical"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Spinner />
          <Typography.Text type="secondary">
            {fetchLoadingMessage({
              current: currentAction.status === 'pending',
              requestLogin: requestLoginAction.status === 'pending',
              requestToken: requestTokenAction.status === 'pending',
            })}
          </Typography.Text>
        </Space>
      </div>
    );
  }

  if (requestLogoutAction.status === 'pending') {
    return (
      <div className="app-spinner">
        <Space
          direction="vertical"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Spinner />
          <Typography.Text type="secondary">
            {messages['platform.start_up.logout']}
          </Typography.Text>
        </Space>
      </div>
    );
  }

  if (!current) {
    return <OwlVisionContainer />;
  }

  return (
    <IntlProvider
      locale={locale}
      defaultLocale="en"
      messages={messages}
      onError={() => {
        /// noop
      }}
    >
      <Locale.Provider
        value={{
          locale,
          messages,
          broadcastMessage: (data) => {
            messageApi[data.type ?? 'info'](messages[data.id]);
          },
        }}
      >
        <ConfigProvider
          theme={{
            hashed: false,
            token: {
              colorPrimary: '#7760D7',
              colorLink: '#7760D7',
            },
          }}
        >
          <SessionContext.Provider value={{ current }}>
            {messageContextHolder}
            <Routing />
          </SessionContext.Provider>
        </ConfigProvider>
      </Locale.Provider>
    </IntlProvider>
  );
};
export default ContextProvider;
