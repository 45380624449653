import React from 'react';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import { useParams } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import Text from 'antd/es/typography/Text';
import Title from 'antd/es/typography/Title';
import { PRETTY_DATE_FORMAT } from '@owl-frontend/components';
import { UnionExpand } from '@owl-lib/type-util';
import { Activity } from '../../../api/activities/interface';
import { Locale } from '../../../context/AppProvider';
import { RouteParams } from '../../../helpers/route-types';
import { RouteDefs } from '../../Routing/Routing';
import { formatString } from '../Utils';
import useClaimActivitiesHook from './useClaimActivitiesHook';
import ActivityCard from './ActivityCard';
import ActivityFilters from './ActivityFilters';
import styles from './ClaimActivitiesContainer.module.scss';

type Params = RouteParams<RouteDefs.ClaimOverview>;

const ClaimActivitiesContainer: React.FC = () => {
  const { messages } = React.useContext(Locale);
  const { dossierId } = useParams() as UnionExpand<Params>;
  const [{ activities }, activitiesHandler] = useClaimActivitiesHook();
  const [selectedActivity, setSelectedActivity] =
    React.useState<Activity | null>(null);

  const menuRefs = React.useRef<Record<string, HTMLDivElement | null>>({});
  const activityRefs = React.useRef<Record<string, HTMLDivElement | null>>({});

  const [searchValue, setSearchValue] = React.useState('');
  const [filteredType, setFilteredType] = React.useState<string[] | null>(null);
  const [sortDesc, setSortDesc] = React.useState(true);
  const [activityTypes, setActivityTypes] = React.useState<string[]>([]);

  const fuse = React.useMemo(() => {
    return new Fuse(activities[dossierId], {
      isCaseSensitive: false,
      useExtendedSearch: true,
      keys: ['activity_type', 'description'],
    });
  }, [activities, dossierId]);

  const filteredActivities = React.useMemo(() => {
    if (!activities[dossierId]) {
      return [];
    }
    let searchedActivities = activities[dossierId];
    if (searchValue && searchValue.trim() !== '') {
      searchedActivities = fuse
        .search(`'${searchValue.trim()}`)
        .map((result) => result.item as Activity);
    }
    if (filteredType && filteredType.length > 0) {
      searchedActivities = searchedActivities.filter((activity: Activity) =>
        filteredType.includes(activity.activity_type)
      );
    }
    searchedActivities = [...searchedActivities].sort((a, b) =>
      sortDesc
        ? dayjs(b.created_at).diff(dayjs(a.created_at))
        : dayjs(a.created_at).diff(dayjs(b.created_at))
    );
    return searchedActivities;
  }, [searchValue, fuse, filteredType, sortDesc, activities, dossierId]);

  React.useEffect(() => {
    if (selectedActivity) {
      const cardRef = activityRefs.current[selectedActivity.activity_id];
      if (cardRef) {
        cardRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
        cardRef.focus();
      }
      const menuItemRef = menuRefs.current[selectedActivity.activity_id];
      if (menuItemRef) {
        menuItemRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [selectedActivity]);

  const menuItems = React.useMemo(
    () =>
      filteredActivities?.map((activity: Activity) => ({
        className: styles.menuItem,
        key: activity.activity_id,
        label: (
          <div
            className={styles.activityInfo}
            ref={(el) => (menuRefs.current[activity.activity_id] = el)}
          >
            <Text strong className={styles.activityType}>
              {formatString(activity.activity_type)}
            </Text>
            <br />
            <Text type="secondary" className={styles.activityDate}>
              {dayjs(activity.created_at).format(PRETTY_DATE_FORMAT)}
            </Text>
          </div>
        ),
        onClick: () => {
          setSelectedActivity(activity);
        },
      })),
    [filteredActivities]
  );

  const activityCards = React.useMemo(
    () =>
      filteredActivities?.map((activity: Activity) => (
        <div key={activity.activity_id}>
          <ActivityCard
            activity={activity}
            activityRefs={activityRefs}
            setSelectedActivity={setSelectedActivity}
          />
        </div>
      )),
    [filteredActivities]
  );

  React.useEffect(() => {
    activitiesHandler.fetchActivities(dossierId);
  }, [dossierId, activitiesHandler]);

  React.useMemo(() => {
    const uniqueActivityTypes = Array.from(
      new Set<string>(
        activities[dossierId]?.map(
          (activity: Activity) => activity.activity_type
        ) || []
      )
    ).sort();
    setActivityTypes(uniqueActivityTypes);
  }, [activities, dossierId]);

  return (
    <Layout>
      <Layout.Sider theme="light" className={styles.sider}>
        <Title className={styles.menuTitle} level={5}>
          {messages['claim_overview.activities.title']}
        </Title>
        <Menu
          mode="inline"
          selectedKeys={[selectedActivity?.activity_id || '']}
          items={menuItems}
        />
      </Layout.Sider>
      <div className={styles.contentContainer}>
        <ActivityFilters
          setSearchValue={setSearchValue}
          setFilteredType={setFilteredType}
          setSortDesc={setSortDesc}
          sortDesc={sortDesc}
          activityTypes={activityTypes}
        />
        <div>{activityCards}</div>
      </div>
    </Layout>
  );
};

export default ClaimActivitiesContainer;
