import {
  TypedUseSelectorHook,
  useSelector as useSelectorRedux,
} from 'react-redux';
import { OwlStore } from '@owl-frontend/redux';
import claimOverviewSlice from '../components/ClaimOverviewContainer/data/logic';
import claimsSlice from '../components/ClaimsContainer/data/logic';
import activitiesSlice from './activities/logic';
import filesSlice from './files/logic';
import localizationSlice from './localization/logic';
import searchSlice from './search/logic';
import sessionSlice from './session/logic';
import storeOptions from './storeOptions';
import usersSlice from './users/logic';

const store = new OwlStore([], {}, storeOptions);
store.addSlice(claimOverviewSlice);
store.addSlice(claimsSlice);
store.addSlice(localizationSlice);
store.addSlice(sessionSlice);
store.addSlice(usersSlice);
store.addSlice(filesSlice);
store.addSlice(activitiesSlice);
store.addSlice(searchSlice);
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('../components/ClaimOverviewContainer/data/logic', () =>
    store.addSlice(claimOverviewSlice, true)
  );
  module.hot.accept('../components/ClaimsContainer/data/logic', () =>
    store.addSlice(claimsSlice, true)
  );
  module.hot.accept('./localization/logic', () =>
    store.addSlice(localizationSlice, true)
  );
  module.hot.accept('./session/logic', () =>
    store.addSlice(sessionSlice, true)
  );
  module.hot.accept('./users/logic', () => store.addSlice(usersSlice, true));
  module.hot.accept('./files/logic', () => store.addSlice(filesSlice, true));
  module.hot.accept('./activities/logic', () =>
    store.addSlice(activitiesSlice, true)
  );
  module.hot.accept('./search/logic', () => store.addSlice(searchSlice, true));
}
export interface StoreState {
  [claimOverviewSlice.name]: (typeof claimOverviewSlice)['initialState'];
  [claimsSlice.name]: (typeof claimsSlice)['initialState'];
  [localizationSlice.name]: (typeof localizationSlice)['initialState'];
  [sessionSlice.name]: (typeof sessionSlice)['initialState'];
  [usersSlice.name]: (typeof usersSlice)['initialState'];
  [filesSlice.name]: (typeof filesSlice)['initialState'];
  [activitiesSlice.name]: (typeof activitiesSlice)['initialState'];
  [searchSlice.name]: (typeof searchSlice)['initialState'];
}
export default store as OwlStore<any> as OwlStore<StoreState>;
export const useSelector: TypedUseSelectorHook<StoreState> = useSelectorRedux;
