import React from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import type { Claim } from '../../api/claims/interface';
import type { StoreState } from '../../data/store';
import claimsSlice from './data/logic';

export interface ClaimsHookState {
  claims: Claim[];
  filters: {
    name_or_id?: string;
    intake_date?: [dayjs.Dayjs, dayjs.Dayjs];
    owners?: string[];
    status?: 'all' | 'OPEN' | 'CLSD';
  };
  sort: {
    key: string[];
    order: 'ascend' | 'descend';
  } | null;
  actions: StoreState['claims']['actions'];
}

interface ClaimsHookHandler {
  fetchClaims(data: {
    filters: ClaimsHookState['filters'];
    sort: ClaimsHookState['sort'];
  }): void;
  onFilterChange(data: { filters: ClaimsHookState['filters'] }): void;
  onSortChange(
    data: { key: string[]; order: 'ascend' | 'descend' } | null
  ): void;
}

const useClaimsHook = (): [ClaimsHookState, ClaimsHookHandler] => {
  const dispatch = useDispatch();
  const { claims, actions } = useSelector((s: StoreState) => s['claims']);
  const { current } = useSelector((s: StoreState) => s['session']);

  const [filters, setFilters] = React.useState<ClaimsHookState['filters']>({
    owners: current?.email ? [current?.email] : [],
    status: 'all',
  });

  const [sort, setSort] = React.useState<ClaimsHookState['sort']>({
    key: ['data', 'cid_date'],
    order: 'descend',
  });

  const handler = React.useMemo(
    () => ({
      fetchClaims: (data: {
        filters: ClaimsHookState['filters'];
        sort: ClaimsHookState['sort'];
      }) => {
        dispatch(claimsSlice.actions.fetchClaims(data));
      },
      onFilterChange: (data: { filters: ClaimsHookState['filters'] }) => {
        setFilters(data.filters);
      },
      onSortChange: (
        data: { key: string[]; order: 'ascend' | 'descend' } | null
      ) => {
        setSort(data);
      },
    }),
    []
  );

  return [
    {
      claims,
      filters,
      sort,
      actions,
    },
    handler,
  ];
};

export default useClaimsHook;
