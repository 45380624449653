import React from 'react';
import {
  generatePath as generatePathRR,
  Routes,
  Route,
} from 'react-router-dom';
import type { Empty } from '@owl-frontend/components';
import { CombineObjectType, enumSet } from '@owl-lib/type-util';
import type { GeneratePathParam } from '../../helpers/route-types';
import AdminPanelContainer from '../AdminPanelContainer/AdminPanelContainer';
import UsersContainer from '../AdminPanelContainer/UsersContainer/UsersContainer';
import ClaimOverviewContainer from '../ClaimOverviewContainer/ClaimOverviewContainer';
import ClaimsContainer from '../ClaimsContainer/ClaimsContainer';
import MainContainer from '../MainContainer/MainContainer';
import OwlVisionLandingContainer from '../OwlVisionLandingContainer/OwlVisionLandingContainer';
import DefaultRedirect from './DefaultRedirect';

export enum RouteDefs {
  Main = '/',
  Broken = '/uh-oh',
  Admin = '/admin',
  AdminUsers = '/admin/users',
  Claims = '/claims',
  ClaimOverview = '/claims/:dossierId',
}

export const routeSet = enumSet(RouteDefs);

type GeneratePathRest<Params> = Empty extends Params
  ? [params?: Params]
  : [params: Params];
type TypedGeneratePath = <R extends string>(
  path: R,
  ...rest: GeneratePathRest<CombineObjectType<GeneratePathParam<R>>>
) => string;
export const generatePath = generatePathRR as TypedGeneratePath;

const Routing = () => (
  <Routes>
    <Route path={RouteDefs.Broken} element={<OwlVisionLandingContainer />} />
    <Route path={RouteDefs.Main} element={<MainContainer />}>
      <Route index element={<DefaultRedirect path={RouteDefs.Claims} />} />
      <Route path={RouteDefs.Admin} element={<AdminPanelContainer />}>
        <Route path={RouteDefs.AdminUsers} element={<UsersContainer />} />
      </Route>
      <Route path={RouteDefs.Claims} element={<ClaimsContainer />} />
    </Route>
    <Route
      path={RouteDefs.ClaimOverview}
      element={<ClaimOverviewContainer />}
    />
  </Routes>
);

export default Routing;
