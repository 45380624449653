export function getFieldId(
  name: string,
  formName?: string
): string | undefined {
  if (!name.length) return undefined;

  const mergedId = name.replace(/[.[\]]+/g, '_');
  return formName ? `${formName}_${mergedId}` : mergedId;
}

export const setValue = ([field, value], state, { changeValue }) => {
  changeValue(state, field, () => value);
};
