import React from 'react';
import { ApartmentOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { Form } from '@owl-frontend/components';
import { Locale } from '../../../context/AppProvider';
import styles from './UsersContainer.module.scss';

export interface UserForm {
  email: string;
  client_user_id: string;
  roles: string[];
}

interface UserCreateFormProps {
  existingEmails: string[];
  isModalVisible: boolean;
  setIsModalVisible(isModalVisible: boolean): void;
}

const UserCreateForm: Form.MFC<UserCreateFormProps, UserForm> = ({
  existingEmails,
  isModalVisible,
  setIsModalVisible,
  // RFF
  form,
  handleSubmit,
}) => {
  const { messages } = React.useContext(Locale);
  const onCancel = () => {
    form.reset();
    setIsModalVisible(false);
  };

  return (
    <Modal
      data-testid="user-modal"
      maskClosable={false}
      title="New User"
      open={isModalVisible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel} data-testid="cancel-button">
          {messages['users.create.buttons.cancel']}
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={handleSubmit}
          data-testid="create-button"
        >
          {messages['users.create.buttons.add']}
        </Button>,
      ]}
    >
      <form onSubmit={handleSubmit}>
        <Form.InputManaged
          name="email"
          label={messages['users.create.modal.email.label']}
          placeholder={messages['users.create.modal.email.placeholder']}
          data-testid="user-email"
          prefix={<MailOutlined className={styles.inputIcon} />}
          validate={Form.Validators.combineValidators(
            [
              Form.Validators.required({
                errorMessage: messages['shared.form.validators.required'],
              }),
              Form.Validators.email({
                errorMessage: messages['users.validators.email.invalid'],
              }),
            ].concat(
              Form.Validators.notIn({
                values: existingEmails,
                errorMessage: messages['users.validators.email.unique'],
              })
            )
          )}
        />

        <Form.InputManaged
          name="client_user_id"
          label={messages['users.create.modal.employee_id.label']}
          data-testid="user-id"
          prefix={<ApartmentOutlined className={styles.inputIcon} />}
          placeholder={messages['users.create.modal.employee_id.placeholder']}
          validate={Form.Validators.required({
            errorMessage: messages['shared.form.validators.required'],
          })}
        />

        <Form.SelectAntdManaged
          name="roles"
          label={messages['users.create.modal.role.label']}
          placeholder={messages['users.create.modal.role.placeholder']}
          data-testid="user-roles"
          validate={Form.Validators.combineValidators([
            Form.Validators.required({
              errorMessage: messages['shared.form.validators.required'],
            }),
          ])}
          options={[
            {
              label: messages['users.create.modal.role.options.assignee'],
              value: messages['users.create.modal.role.options.assignee'],
            },
            {
              label: messages['users.create.modal.role.options.manager'],
              value: messages['users.create.modal.role.options.manager'],
            },
          ]}
        />
      </form>
    </Modal>
  );
};

const UserCreateFormManaged = Form.manage(UserCreateForm);

interface NewUserModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
  createUser: (User) => void;
  existingEmails: string[];
}

const NewUserModal: React.FC<NewUserModalProps> = ({
  isModalVisible,
  setIsModalVisible,
  createUser,
  existingEmails,
}) => {
  return (
    <UserCreateFormManaged
      onSubmit={createUser}
      existingEmails={existingEmails}
      isModalVisible={isModalVisible}
      setIsModalVisible={setIsModalVisible}
    />
  );
};

export default NewUserModal;
