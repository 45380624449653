import React from 'react';
import { Layout, Menu } from 'antd';
import { Locale } from '../../../context/AppProvider';
import styles from './ClaimReportContainer.module.scss';
import ClaimReportOverviewContainer from './ClaimReportOverviewContainer/ClaimReportOverviewContainer';
import ClaimReportOverviewDataContainer, {
  CATEGORY_DATAPOINTS_MAPPING,
} from './ClaimReportOverviewDataContainer/ClaimReportOverviewDataContainer';

const ClaimReportContainer: React.FC = () => {
  const { messages } = React.useContext(Locale);

  const [activeCategory, setActiveCategory] = React.useState('overview');
  const [selectedKeys, setSelectedKeys] = React.useState([
    'overview',
    'overview.summary',
  ]);

  const onTitleClick = React.useCallback((key: string, subKeys: string[]) => {
    setActiveCategory(key);
    setSelectedKeys(subKeys);
  }, []);

  const claimReportMenuItems = React.useMemo(() => {
    return [
      {
        key: 'overview',
        label: messages['claim_overview.report.menu.overview.title'],
        onTitleClick: () => {
          onTitleClick('overview', ['overview', 'overview.summary']);
        },
        active: true,
        children: [
          'summary',
          'claimant',
          'claim_status_and_history',
          // 'policy_and_coverage',
          'medical',
          // 'assessment_and_rtw',
        ].map((overviewItemKey) => ({
          key: `overview.${overviewItemKey}`,
          label:
            messages[`claim_overview.report.menu.overview.${overviewItemKey}`],
          onClick: () => {
            onTitleClick('overview', [
              'overview',
              `overview.${overviewItemKey}`,
            ]);
          },
        })),
      },
      {
        key: 'medical_assessment',
        label: messages['claim_overview.report.menu.medical_assessment.title'],
        children: [
          ...CATEGORY_DATAPOINTS_MAPPING['medical_assessment'],
          'providers',
        ].map((medical_assessmentItemKey) => ({
          key: `medical_assessment.${medical_assessmentItemKey}`,
          label:
            messages[
              `claim_overview.report.menu.medical_assessment.${medical_assessmentItemKey}`
            ],
          onClick: () => {
            onTitleClick('medical_assessment', [
              'medical_assessment',
              `medical_assessment.${medical_assessmentItemKey}`,
            ]);
          },
        })),
        onTitleClick: () => {
          onTitleClick('medical_assessment', [
            'medical_assessment',
            'medical_assessment.office_visit_records',
          ]);
        },
      },
      {
        key: 'correspondence',
        label: messages['claim_overview.report.menu.correspondence.title'],
        onClick: () => {
          onTitleClick('correspondence', ['correspondence']);
        },
      },
    ];
  }, [messages, onTitleClick]);

  return (
    <Layout hasSider>
      <Layout.Sider theme="light">
        <Menu
          expandIcon={null}
          items={claimReportMenuItems}
          openKeys={[activeCategory]}
          selectedKeys={selectedKeys}
          mode="inline"
        />
      </Layout.Sider>
      <Layout.Content className={styles.content}>
        <div className={styles.contentContainer}>
          {activeCategory === 'overview' && <ClaimReportOverviewContainer />}
          {activeCategory !== 'overview' && (
            <ClaimReportOverviewDataContainer activeCategory={activeCategory} />
          )}
          <div className={styles.contentPadding} />
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default ClaimReportContainer;
