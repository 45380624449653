import type { AllEffect } from 'redux-saga/effects';
import { setIn } from 'timm';
import { all } from 'typed-redux-saga';
import {
  AsyncActionState,
  asyncActionStateMatchers,
  setupSlice,
  invoke,
} from '@owl-frontend/redux';
import filesApiClient, {
  FILE_CONTENT_TIMEOUT_LIMIT,
} from '../../api/files/client';
import type { ClaimFile } from '../../api/files/interface';

export interface FilesState {
  files: {
    [dossier_id: string]: Record<string, ClaimFile>;
  };
  filesContent: {
    [fileId: string]: {
      [pageNumber: string]: {
        url: string;
        requestedDate: string;
      };
    };
  };
  actions: {
    files: AsyncActionState;
    fetchFile: AsyncActionState;
  };
}

const initialState: FilesState = {
  files: {},
  filesContent: {},
  actions: {
    files: { status: 'uninitialized' },
    fetchFile: { status: 'uninitialized' },
  },
};

function* fetchFile(action: {
  payload: {
    fileId: string;
    pages: number[];
  };
}): Generator<AllEffect<Promise<{ url: string; page: number }>>> {
  const { fileId, pages } = action.payload;
  return yield* all(
    pages.map(async (p) => {
      const { url } = await filesApiClient.fetchFilePage({
        fileId,
        pageStart: p,
      });

      return {
        url,
        page: p,
      };
    })
  );
}

const slice = setupSlice('files', initialState)
  .addAsyncSagas({
    files: invoke(filesApiClient.fetch),

    downloadFile: invoke(filesApiClient.downloadFile),
  })
  .addAsyncSagas(
    {
      fetchFile,
    },
    {
      timeoutLimit: FILE_CONTENT_TIMEOUT_LIMIT,
    }
  )
  .addReducers({
    'files/fulfilled': (state, action) => {
      const dossier_id = action.meta.arg;
      const claimFileMap: Record<string, ClaimFile> = action.payload.reduce(
        (acc, file) => {
          acc[file.file_id] = file;
          return acc;
        },
        {} as Record<string, ClaimFile>
      );
      state.files[dossier_id] = claimFileMap;
    },
    'fetchFile/fulfilled': (state, action) => {
      for (const payload of action.payload) {
        state.filesContent = setIn(
          state.filesContent,
          [action.meta.arg.fileId, payload.page],
          {
            url: payload.url,
            requestedDate: new Date().getTime(),
          }
        ) as typeof state.filesContent;
      }
    },
    'downloadFile/fulfilled': (state, action) => {
      window.open(action.payload.url);
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
