import React from 'react';
import { capitalCase } from 'change-case';
import dayjs from 'dayjs';
import { getIn } from 'timm';
import { Descriptions, Space, Table } from 'antd';
import { DescriptionsItemType } from 'antd/es/descriptions';
import { ColumnType, ExpandableConfig } from 'antd/es/table/interface';
import { PRETTY_DATE_FORMAT } from '@owl-frontend/components';
import { createLogger } from '@owl-lib/logger';
import { ClaimDatapoint } from '../../../../../api/claims/interface';
import { Locale } from '../../../../../context/AppProvider';
import { ClaimDetailsContext } from '../../../ClaimOverviewContainer';
import { CitationComponent } from '../../../ClaimSummaryComponent/ClaimSummariesComponent';
import { FETCH_DATAPOINTS_LIMIT } from '../../../data/logic';
import datapointSchemas from '../schemas.json';
import styles from './DatapointsRenderEngineComponent.module.scss';

const logger = createLogger(__filename);

type RenderStringDef = {
  type: 'string';
};

type RenderBooleanDef = {
  type: 'boolean';
};

type RenderDateDef = {
  type: 'date';
};

type RenderProviderDef = {
  type: 'provider';
};

type RenderObjectDef = {
  type: 'object';
  children: {
    [k: string]:
      | RenderStringDef
      | RenderBooleanDef
      | RenderDateDef
      | RenderObjectDef
      | RenderListDef
      | RenderProviderDef;
  };
};

type RenderListDef = {
  type: 'list';
  children:
    | RenderStringDef
    | RenderBooleanDef
    | RenderDateDef
    | RenderObjectDef
    | RenderProviderDef;
};

interface DatapointEngineProps {
  datapointType: string;
  data: ClaimDatapoint[];
  totalCount?: number;
  tableDef: {
    columns: string[];
  };
  detailRenderDef: {
    [k: string]:
      | RenderStringDef
      | RenderBooleanDef
      | RenderProviderDef
      | RenderDateDef
      | RenderObjectDef
      | RenderListDef
      | {
          type: never;
        };
  };
  onPage(data: { datapointType: string; offset: number }): void;
}

const EMPTY = '-';

// TODO: style and hook up details pop up
const ProviderRenderComponent: React.FC<{ provider_id: string }> = ({
  provider_id,
}) => {
  const { providers = { data: {} } } = React.useContext(ClaimDetailsContext);
  if (!providers.data[provider_id]) {
    return <span>{provider_id}</span>;
  }

  return <span>{providers.data[provider_id].provider_name}</span>;
};

const DetailedRenderComponent: React.FC<{
  datapointSchema: DatapointEngineProps['detailRenderDef'];
  datapointType: string;
  record: ClaimDatapoint['content'];
}> = ({ datapointSchema, datapointType, record }) => {
  const items = React.useMemo(() => {
    const result: DescriptionsItemType[] = [];

    for (const field of Object.keys(record)) {
      const schema = datapointSchema?.[field];
      if (!schema) {
        logger.error(
          `Failed to find schema for field! ${JSON.stringify(
            { datapointSchema, field },
            null,
            2
          )}`
        );
        continue;
      }

      if (!record[field]) {
        result.push({
          key: field,
          label: capitalCase(field),
          children: EMPTY,
        });
        continue;
      }

      if (schema.type === 'string' || schema.type === 'boolean') {
        result.push({
          key: field,
          label: capitalCase(field),
          children: record[field],
        });
      }

      if (schema.type === 'provider') {
        result.push({
          key: field,
          label: capitalCase(field),
          children: <ProviderRenderComponent provider_id={record[field]} />,
        });
      }

      if (schema.type === 'date') {
        result.push({
          key: field,
          label: capitalCase(field),
          children: dayjs(record[field]).format(PRETTY_DATE_FORMAT),
        });
      }

      if (schema.type === 'list') {
        let children: React.ReactNode = EMPTY;

        if (!Array.isArray(record[field])) {
          logger.error(
            `Non-list value found in expected list schema! ${JSON.stringify(
              {
                datapointSchema,
                field,
              },
              null,
              2
            )}`
          );
          result.push({
            key: field,
            label: capitalCase(field),
            children: record[field],
          });
          continue;
        }

        if (schema.children.type === 'object') {
          children = (
            <Space direction="vertical">
              {record[field].map((d, index) => (
                <DetailedRenderComponent
                  key={`${field}--${index}`}
                  datapointType={datapointType}
                  record={d}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  datapointSchema={schema.children.children}
                />
              ))}
            </Space>
          );
        }

        if (
          schema.children.type === 'string' ||
          schema.children.type === 'boolean'
        ) {
          children = record[field].join(', ');
        }

        if (schema.children.type === 'provider') {
          children = (
            <div>
              {record[field].map((provider_id) => (
                <ProviderRenderComponent
                  key={provider_id}
                  provider_id={provider_id}
                />
              ))}
            </div>
          );
        }

        if (schema.children.type === 'date') {
          children = record[field]
            .map((d) => dayjs(d).format(PRETTY_DATE_FORMAT))
            .join(', ');
        }

        result.push({
          key: field,
          label: capitalCase(field),
          children,
        });
      }

      if (schema.type === 'object') {
        result.push({
          key: field,
          label: capitalCase(field),
          children: (
            <DetailedRenderComponent
              record={record[field]}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              datapointSchema={schema.children as RenderObjectDef}
            />
          ),
        });
      }
    }

    return result;
  }, [datapointSchema, datapointType, record]);

  return (
    <Descriptions
      className={styles.descriptionsView}
      bordered
      items={items}
      column={1}
      size="small"
    />
  );
};

const expandable = (
  datapointType: string
): ExpandableConfig<ClaimDatapoint> => ({
  expandedRowClassName: () => {
    return styles.expandedRow;
  },
  expandedRowRender: (record: ClaimDatapoint) => {
    if (!datapointSchemas[datapointType]?.data) {
      return false;
    }

    return (
      <div className={styles.expandedRowDetails}>
        <DetailedRenderComponent
          datapointType={datapointType}
          record={record.content}
          datapointSchema={datapointSchemas[datapointType].data}
        />
      </div>
    );
  },
});

const fetchDataKeyPath = (dataIndex: string): string[] => {
  const result: string[] = [];
  const dataIndexKeys = dataIndex.split('.');
  for (const [index, key] of dataIndexKeys.entries()) {
    result.push(key);
    if (index < dataIndexKeys.length - 1) {
      result.push('children');
    }
  }

  return result;
};

const DatapointEngineComponent: React.FC<DatapointEngineProps> = ({
  datapointType,
  data,
  totalCount,
  tableDef,
  detailRenderDef,
  onPage,
}) => {
  const { messages } = React.useContext(Locale);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);
  const columns = React.useMemo(() => {
    const result = tableDef.columns.map((c) => {
      const columnConfig: ColumnType<ClaimDatapoint> = {
        key: c,
        dataIndex: ['content', ...c.split('.')],
        title: capitalCase(c),
      };

      const dataType = getIn(detailRenderDef, [...fetchDataKeyPath(c), 'type']);

      if (dataType === 'date') {
        columnConfig.render = (_, record) => {
          const value = getIn(record.content, c.split('.')) as string;
          if (!value) {
            return EMPTY;
          }

          return dayjs(value).format(PRETTY_DATE_FORMAT);
        };
      }

      if (dataType === 'provider') {
        columnConfig.render = (_, record) => {
          const value = getIn(record.content, c.split('.')) as string;
          if (!value) {
            return EMPTY;
          }

          return (
            <ProviderRenderComponent
              provider_id={getIn(record.content, c.split('.')) as string}
            />
          );
        };
      }

      if (dataType === 'list') {
        columnConfig.render = (_, record) => {
          const value = getIn(record.content, c.split('.')) as string;

          if (!value || value.length === 0) {
            return EMPTY;
          }

          if (
            getIn(detailRenderDef, [
              ...fetchDataKeyPath(c),
              'children',
              'type',
            ]) === 'provider'
          ) {
            return (
              <ProviderRenderComponent
                provider_id={getIn(record.content, c.split('.')) as string}
              />
            );
          }

          return value;
        };
      }

      return columnConfig;
    });

    result.push({
      key: 'source',
      dataIndex: 'citations',
      title: messages['claim_overview.report.sources'],
      render: (_, record) => {
        return (
          <>
            {record.citations.map((c, index) => (
              <CitationComponent
                key={c.citation_id}
                citation={c}
                citationIndex={index + 1}
              />
            ))}
          </>
        );
      },
    });

    return result;
  }, [detailRenderDef, messages, tableDef.columns]);

  const onExpand = React.useCallback((expanded, record) => {
    setExpandedRowKeys((prev) => {
      if (expanded) {
        return [...prev, record.datapoint_id];
      }

      return prev.filter(
        (datapoint_id) => datapoint_id !== record.datapoint_id
      );
    });
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div>
      <Table<ClaimDatapoint>
        rowKey="datapoint_id"
        dataSource={data}
        expandable={{
          ...expandable(datapointType),
          expandedRowKeys,
          onExpand,
        }}
        columns={columns}
        pagination={{
          onChange: (page: number) => {
            onPage({ datapointType, offset: page - 1 });
          },
          pageSize: FETCH_DATAPOINTS_LIMIT,
          showSizeChanger: false,
          total: totalCount,
        }}
      />
    </div>
  );
};

export default DatapointEngineComponent;
