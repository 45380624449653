import React from 'react';
import dayjs from 'dayjs';
import { useField } from 'react-final-form';
import type { RangeProps as DateRangeAntdProps } from '../../components/datepicker/DatePickerAntd';
import { DateRangePickerAntd } from '../../components/datepicker/DatePickerAntd';
import ErrorDisplayComponent from '../ErrorDisplayComponent';
import type { ManagedFieldProps } from '../interface';

export interface Props
  extends Omit<DateRangeAntdProps, 'onChange' | 'defaultValue'>,
    ManagedFieldProps {
  name: string;
}

const DateRangePickerAntdManaged: React.FC<Props> = ({
  name,
  defaultValue,
  validate,
  ...dateRangeProps
}) => {
  const { input, meta } = useField(name, { defaultValue, validate });
  const { error, invalid, modified, touched } = meta;

  type OnChange = NonNullable<DateRangeAntdProps['onChange']>;
  const onChange = React.useCallback<OnChange>(
    (e) => {
      input.onChange(e);
    },
    [input]
  );

  const modifiedOrTouched = modified || touched;
  const fetchErrorMessage = React.useCallback(() => {
    if (!modifiedOrTouched || !invalid || !error) {
      return null;
    }

    return error;
  }, [modifiedOrTouched, invalid, error]);
  return (
    <DateRangePickerAntd
      {...dateRangeProps}
      name={name}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      onChange={onChange}
      trailer={<ErrorDisplayComponent errorMessage={fetchErrorMessage()} />}
    />
  );
};

export default DateRangePickerAntdManaged;
